// @ts-ignore
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { hotjar } from 'react-hotjar';
import Store from 'store';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';

import { LicenseManager } from 'ag-grid-enterprise';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from 'ui';
import * as serviceWorker from './serviceWorker';
import 'global.less';

const APP_ENV = process.env.REACT_APP_ENV;
if ((APP_ENV === 'production' || APP_ENV === 'testing') && process.env.REACT_APP_SENTRY_DSN_KEY) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN_KEY,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
    environment: APP_ENV,
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === 'console') {
        return null;
      }
      return breadcrumb;
    },
  });
}
// @ts-ignore
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);

process.env.REACT_APP_HOTJAR_ID &&
  // @ts-ignore
  hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SV);

ReactDOM.render(
  <Provider store={Store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
