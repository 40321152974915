import { produce } from 'immer';
import initialState, { Walls /* , { Walls } */ } from 'store/state/walls';
import {
  LIST_WALLS_REQ,
  LIST_WALLS_SUCCESS,
  LIST_WALLS_FAILED,
  VIEW_WALL,
  RESET_WALL_SEARCH,
  SEARCH_WALL,
  RESET_WALLS,
  // WallsActions,
} from 'store/actions/actionTypes';

export default (state: Walls = initialState, action: any): Walls =>
  produce(state, (draft) => {
    // let filterSearchValue;
    switch (action.type) {
      case LIST_WALLS_REQ:
        // case EDIT_WALL_REQ:
        draft.isLoading = true;
        break;
      case LIST_WALLS_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        draft.searchType = '';
        draft.filterResult = [];
        break;
      // case EDIT_WALL_SUCCESS:
      case LIST_WALLS_FAILED:
        draft.isLoading = false;
        break;
      case VIEW_WALL:
        draft.selected = action.payload;
        break;
      case SEARCH_WALL:
        const filterSearch = draft.data.filter(
          (item: any) => item.name.toLowerCase().includes(action.payload.toLowerCase()),
          /* eslint-disable-next-line */
        );
        draft.searchType = action.payload;
        draft.filterResult = filterSearch;
        break;
      case RESET_WALL_SEARCH:
        draft.searchType = '';
        draft.filterResult = draft.data;
        break;
      case RESET_WALLS:
        draft.data = initialState.data;
        draft.filterResult = initialState.filterResult;
        draft.isLoading = false;
        draft.searchType = initialState.searchType;
        draft.selected = initialState.selected;
        break;
      default:
        break;
    }
  });
