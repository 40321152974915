import { GRID_NAMES_TYPE } from 'utils/types';

const baseUrl = `${process.env.REACT_APP_ROOT_URL}/api`;
const baseBulkUploadUrl = `${process.env.REACT_APP_ROOT_URL}/bulk/api`;
const baseCubejsUrl = process.env.REACT_APP_CUBEJS_ROOT_URL
  ? `${process.env.REACT_APP_CUBEJS_ROOT_URL}/cubejs/load`
  : 'https://wakecap.azure-api.net/cubejs/load';

export default {
  user: {
    login: `${baseUrl}/login`,
    siteLogin: (siteId: string): string => `${baseUrl}/sites/${siteId}/login`,
    logout: `${baseUrl}/logout`,
    create: `${baseUrl}/invite`,
    resetPassword: `${baseUrl}/reset-password`,
    register: `${baseUrl}/register`,
    forgotPassword: `${baseUrl}/forgot-password`,
    list: `${baseUrl}/users`,
    clients: (userId: string): string => `${baseUrl}/users/${userId}/clients`,
    sites: (userId: string): string => `${baseUrl}/users/${userId}/sites`,
    deactivate: (userId: string): string => `${baseUrl}/users/${userId}/deactivate`,
    activate: (userId: string): string => `${baseUrl}/users/${userId}/activate`,
    edit: (userId: string): string => `${baseUrl}/users/${userId}`,
    resendInvitation: (userId: string): string => `${baseUrl}/users/${userId}/reinvite`,
    userPreference: `${baseUrl}/users/preferences`,
    currentUser: `${baseUrl}/user`,
    recentlyVisited: `${baseUrl}/users/recently-visited`,
    resetWorkerPassword: (userId: string): string => `${baseUrl}/users/${userId}/reset-password`,
  },
  clients: {
    list: `${baseUrl}/clients`,
    create: `${baseUrl}/clients`,
  },
  sites: {
    list: (clientId: string): string => `${baseUrl}/clients/${clientId}/sites`,
    create: `${baseUrl}/sites`,
    listNetworks: `${baseUrl}/networks/unassigned`,
    assignNetwork: (networkId: string): string => `${baseUrl}/networks/${networkId}`,
    createSubContractor: (siteId: string): string => `${baseUrl}/sites/${siteId}/subcontractors`,
    editSubContractor: (siteId: string, id: string): string =>
      `${baseUrl}/sites/${siteId}/subcontractors/${id}`,
    rolesList: (siteId: string): string => `${baseUrl}/sites/${siteId}/worker-roles`,
    contractorsList: (siteId: string): string => `${baseUrl}/sites/${siteId}/subcontractors`,
    listPowerBI: (siteId: string): string => `${baseUrl}/sites/${siteId}/power-bi`,
    inventoryMeta: (siteId: string): string => `${baseUrl}/sites/${siteId}/inventory-meta`,
    editWorkerRole: (siteId: string, roleId: string): string =>
      `${baseUrl}/sites/${siteId}/worker-roles/${roleId}`,
    createPowerBiReport: (siteId: string): string => `${baseUrl}/sites/${siteId}/power-bi`,
    updatePowerBiReport: (siteId: string, reportId: string): string =>
      `${baseUrl}/sites/${siteId}/power-bi/${reportId}`,
  },
  buildings: {
    list: (siteId: string): string => `${baseUrl}/sites/${siteId}/buildings`,
    create: (siteId: string): string => `${baseUrl}/sites/${siteId}/buildings`,
    inventoryMeta: (buildingId: string): string =>
      `${baseUrl}/buildings/${buildingId}/inventory-meta`,
  },
  floors: {
    create: (buildingId: string): string => `${baseUrl}/buildings/${buildingId}/floors`,
    edit: (buildingId: string, floorId: string): string =>
      `${baseUrl}/buildings/${buildingId}/floors/${floorId}`,
    list: (buildingId: string): string => `${baseUrl}/buildings/${buildingId}/floors`,
    view: (buildingId: string, floorId: string): string =>
      `${baseUrl}/buildings/${buildingId}/floors/${floorId}`,
    delete: (buildingId: string, floorId: string): string =>
      `${baseUrl}/buildings/${buildingId}/floors/${floorId}`,
    inventoryMeta: (floorId: string): string => `${baseUrl}/floors/${floorId}/inventory-meta`,
    inventoryStatistics: (floorId: string): string =>
      `${baseUrl}/floors/${floorId}/inventory-statistics`,
    anchorStatistics: (floorId: string): string => `${baseUrl}/floors/${floorId}/anchor-statistics`,
    allFloors: (siteId: string): string => `${baseUrl}/sites/${siteId}/floors?sort=altitude`,
    uploadBulk: (siteId: string): string => `${baseUrl}/sites/${siteId}/location-groups/bulk`,
    locationTemplate: (siteId: string): string =>
      `${baseUrl}/sites/${siteId}/location-groups/download/template`,
    locationAssignmentTemplate: (siteId: string): string =>
      `${baseUrl}/sites/${siteId}/workers/download/template/create-location-group-assignments`,
    uploadBulkLocationGroups: (siteId: string, action: string): string =>
      `${process.env.REACT_APP_ROOT_URL}/bulk/api/sites/${siteId}/location-groups/bulk/${action}`,
  },
  zones: {
    list: (floorId: string): string => `${baseUrl}/floors/${floorId}/zones`,
    create: (floorId: string): string => `${baseUrl}/floors/${floorId}/zones`,
    edit: (floorId: string, zoneId: string): string =>
      `${baseUrl}/floors/${floorId}/zones/${zoneId}`,
    delete: (floorId: string, zoneId: string): string =>
      `${baseUrl}/floors/${floorId}/zones/${zoneId}`,
    allZones: (siteId: string): string => `${baseUrl}/sites/${siteId}/zones`,
  },
  walls: {
    create: (): string => `${baseUrl}/installation/wall/add`,
    getAll: (): string => `${baseUrl}/installation/wall/getAll`,
    delete: (): string => `${baseUrl}/installation/wall/delete`,
    edit: (): string => `${baseUrl}/installation/wall/edit`,
  },
  anchors: {
    listApproved: (floorId: string): string => `${baseUrl}/floors/${floorId}/inventory`,
    listUnApproved: (networkId: string): string => `${baseUrl}/networks/${networkId}/inventory`,
    updateAnchor: (floorId: string, anchorId: string): string =>
      `${baseUrl}/floors/${floorId}/inventory/${anchorId}`,
    nodeDiagnostics: (floorId: string, snapshotTime?: number | null): string =>
      `${baseUrl}/floors/${floorId}/node-information?timestamp=${snapshotTime || ''}`,
  },
  planningAnchors: {
    add: (): string => `${baseUrl}/installation/anchor/add`,
    getAll: (): string => `${baseUrl}/installation/anchor/getAll`,
    delete: (): string => `${baseUrl}/installation/anchor/delete`,
    edit: (): string => `${baseUrl}/installation/anchor/edit`,
  },
  rssiHeatmap: {
    simulate: (): string => `${baseUrl}/installation/anchor/simulate`,
    measured: (): string => `${baseUrl}/installation/node/measured`,
  },
  workers: {
    createWorkerRole: (siteId: string): string => `${baseUrl}/sites/${siteId}/worker-roles`,
    createWorker: (siteId: string): string => `${baseUrl}/sites/${siteId}/workers`,
    updateWorker: (siteId: string, workerId: string): string =>
      `${baseUrl}/sites/${siteId}/workers/${workerId}`,
    list: (siteId: string): string => `${baseUrl}/sites/${siteId}/workers`,
    view: (siteId: string, workerId: number): string =>
      `${baseUrl}/sites/${siteId}/workers/${workerId}`,
    delete: (siteId: string, workerId: number): string =>
      `${baseUrl}/sites/${siteId}/workers/${workerId}`,
    statistics: (siteId: string): string => `${baseUrl}/sites/${siteId}/workers/statistics`,
    unAssignHelmet: (siteId: string, workerId: number): string =>
      `${baseUrl}/sites/${siteId}/workers/${workerId}/inventories/unassign`,
    assignHelmet: (siteId: string, workerId: number, asset_id: number): string =>
      `${baseUrl}/sites/${siteId}/workers/${workerId}/inventories/assign/${asset_id}`,
    assignWorkers: (siteId: string, crewId: string): string =>
      `${baseUrl}/sites/${siteId}/workers/crews/${crewId}/assign`,
    template: (siteId: string): string => `${baseUrl}/sites/${siteId}/workers/download/template`,
    uploadExcel: (siteId: string): string => `${baseUrl}/sites/${siteId}/workers/upload`,
    bulkAssignTemplate: (siteId: string): string =>
      `${baseUrl}/sites/${siteId}/workers/download/template/create-crew-workshift-assignments`,
    uploadBulkAssign: (siteId: string, action: string): string =>
      `${process.env.REACT_APP_ROOT_URL}/bulk/api/sites/${siteId}/workers/crew-workshift/bulk/${action}`,
    crewShiftAssignments: (siteId: string): string =>
      `${baseUrl}/sites/${siteId}/workers/workshift-and-crews/latest`,
    workerHoursTemplate: (siteId: string): string =>
      `${baseUrl}/sites/${siteId}/workers/download/template/create-attendance`,
    bulkWorkerAttendance: (siteId: string): string =>
      `${baseUrl}/sites/${siteId}/workers/attendance/bulk`,
    bulkUploadReportedHours: (siteId: string, action: string): string =>
      `${process.env.REACT_APP_ROOT_URL}/bulk/api/sites/${siteId}/workers/report-hours/bulk/${action}`,
  },
  assets: {
    listUnApproved: (networkId: string): string =>
      `${baseUrl}/networks/${networkId}/inventory/assets`,
    list: (floorId: string | number, siteNetworkId: string): string =>
      `${baseUrl}/installation/${floorId.toString()}/assets/list/${siteNetworkId}`,
    listPlayback: (
      floorId: string | number,
      wntNetworkId: string,
      snapshotTime: number | null,
    ): string =>
      `${baseUrl}/installation/${floorId.toString()}/assets/list/playback/${wntNetworkId}/${snapshotTime}`,
    getAsset: (
      floorId: string | number,
      wntInventoryId: string | number,
      wntNetworkId: string | number,
      snapshotTime?: number | null | undefined,
    ): string =>
      `${baseUrl}/installation/${floorId.toString()}/assets/item/${wntInventoryId.toString()}/${wntNetworkId.toString()}?timestamp=${snapshotTime ||
        ''}`,
  },
  workshifts: {
    createWorkshift: (siteId: string): string => `${baseUrl}/sites/${siteId}/workshifts`,
    list: (siteId: string): string => `${baseUrl}/sites/${siteId}/workshifts`,
  },
  crews: {
    createCrew: (siteId: string): string => `${baseUrl}/sites/${siteId}/crews`,
    list: (siteId: string): string => `${baseUrl}/sites/${siteId}/crews`,
    downloadTemplate: (siteId: string): string =>
      `${baseUrl}/sites/${siteId}/crew-groups/download/template`,
    uploadCrewsGroup: (siteId: string): string => `${baseUrl}/sites/${siteId}/crew-groups/bulk`,
    downloadCrewsManagerTemplate: (siteId: string): string =>
      `${baseUrl}/sites/${siteId}/crews/assign/crew-manager/download/template`,
    uploadBulkAssignCrewManager: (siteId: string, action: string): string =>
      `${process.env.REACT_APP_ROOT_URL}/bulk/api/sites/${siteId}/crew/crew-manager/bulk/${action}`,
    crewGroupsList: (siteId: string): string => `${baseUrl}/sites/${siteId}/crew-groups`,
    downloadCreateCrewTemplate: (): string =>
      `${process.env.REACT_APP_ROOT_URL}/bulk/api/sites/crew/bulk/template`,
    createCrewBulkUpload: (siteId: string, action: string): string =>
      `${process.env.REACT_APP_ROOT_URL}/bulk/api/sites/${siteId}/crew/bulk/${action}`,
  },
  locationGroup: {
    fullList: (siteId: string): string => `${baseUrl}/sites/${siteId}/location-groups`,
    list: (siteId: string): string =>
      `${process.env.REACT_APP_ROOT_URL}/bulk/api/sites/${siteId}/location-groups/bulk/list`,
    uploadBulkAssignment: (siteId: string, action: string): string =>
      `${process.env.REACT_APP_ROOT_URL}/bulk/api/sites/${siteId}/workers/location-groups/assign/bulk/${action}`,
    assignments: (siteId: string): string =>
      `${baseUrl}/sites/${siteId}/location-groups/assignments`,
    manualLocationAssignmentTemplate: (): string =>
      `${process.env.REACT_APP_ROOT_URL}/bulk/api/sites/location-assignment/bulk/template`,
    uploadManualLocationAssignment: (siteId: string, action: string): string =>
      `${process.env.REACT_APP_ROOT_URL}/bulk/api/sites/${siteId}/location-assignment/bulk/${action}`,
    manualLocationAsignmentsList: (siteId: string): string =>
      `${process.env.REACT_APP_ROOT_URL}/bulk/api/sites/${siteId}/location-assignment/bulk/list`,
  },
  productivity: {
    projectSchedule: (siteId: string): string => `${baseUrl}/sites/${siteId}/activity/bulk`,
    productivityList: (siteId: string): string => `${baseUrl}/sites/${siteId}/activity`,
    viewProductivity: (siteId: string, activityId: number): string =>
      `${baseUrl}/sites/${siteId}/activity/${activityId}`,
    activityTemplate: (siteId: string): string =>
      `${baseUrl}/sites/${siteId}/activity-code/download/template`,
    updateProductivity: (siteId: string): string => `${baseUrl}/sites/${siteId}/activity/bulk`,
    delayCodesList: (siteId: string): string => `${baseUrl}/sites/${siteId}/delay-codes`,
    approveBulk: (siteId: string): string => `${baseUrl}/sites/${siteId}/activity/approve/bulk`,
    downloadTemplate: (siteId: string): string =>
      `${baseUrl}/sites/${siteId}/activity/download/template`,
    projectScheduleBulk: (siteId: string, action: string): string =>
      `${process.env.REACT_APP_ROOT_URL}/bulk/api/sites/${siteId}/etqr/bulk/${action}`,
  },
  subcontractor: {
    downloadTemplate: (siteId: string): string =>
      `${baseUrl}/sites/${siteId}/subcontractor-groups/download/template`,
    uploadBulkSubcntGroup: (siteId: string): string =>
      `${baseUrl}/sites/${siteId}/subcontractor-groups/bulk`,
    subcontractorGroupsList: (siteId: string): string =>
      `${baseUrl}/sites/${siteId}/subcontractors-groups`,
  },
  workerRole: {
    downloadTemplate: (): string =>
      `${process.env.REACT_APP_ROOT_URL}/bulk/api/sites/worker-role-group/bulk/template`,
    uploadBulkRoleGroup: (siteId: string, action: string): string =>
      `${process.env.REACT_APP_ROOT_URL}/bulk/api/sites/${siteId}/worker-role-group/bulk/${action}`,
    workerRoleGroupsList: (siteId: string): string =>
      `${process.env.REACT_APP_ROOT_URL}/bulk/api/sites/${siteId}/worker-role-group/bulk/list`,
  },
  workerStatus: {
    list: (siteId: string): string => `${baseUrl}/sites/${siteId}/worker-status`,
    downloadWorkerStatusTemplate: (siteId: string): string =>
      `${baseUrl}/sites/${siteId}/workers/download/template/create-worker-status-assignments`,
    add: (siteId: string): string => `${baseUrl}/sites/${siteId}/workers/worker-status/assign/bulk`,
    workerStatusAssignmentList: (siteId: string): string =>
      `${baseUrl}/sites/${siteId}/workers/worker-status/assignments`,
    uploadWorkerStatus: (siteId: string, action: string): string =>
      `${process.env.REACT_APP_ROOT_URL}/bulk/api/sites/${siteId}/workers/worker-status/assign/bulk/${action}`,
  },
  safety: {
    socket: `${process.env.REACT_APP_SOCKET_URL || process.env.REACT_APP_ROOT_URL}:8080`,
    workers: (): string =>
      `${process.env.REACT_APP_SOCKET_URL ||
        process.env.REACT_APP_ROOT_URL}:8080/api/safety/getworkers`,
    incidents: (
      networkId: string,
      pageSize: string,
      pageNumber: string, // incidents pageNumber starts from ZERO
      incidentTypeFilter = '',
    ): string =>
      `${process.env.REACT_APP_SOCKET_URL ||
        process.env
          .REACT_APP_ROOT_URL}:8080/api/safety/incident/getAll/${pageSize}/${pageNumber}/${networkId}/${incidentTypeFilter}`,
    escelate: (): string =>
      `${process.env.REACT_APP_SOCKET_URL ||
        process.env.REACT_APP_ROOT_URL}:8080/api/safety/incident/escelate`,
  },
  activity: {
    workersList: (siteId: string): string => `${baseUrl}/sites/${siteId}/workers/`,
    codelist: (siteId: string): string => `${baseUrl}/sites/${siteId}/activity-code`,
    bulk: (siteId: string, action: string): string =>
      `${process.env.REACT_APP_ROOT_URL}/bulk/api/sites/${siteId}/activity-codes/bulk/${action}`,
  },
  nodes: {
    list: (siteId: string): string => `${baseUrl}/sites/${siteId}/inventories`,
  },
  bulkupload: {
    crewManagerAssignment: {
      list: (siteId: string): string =>
        `${baseBulkUploadUrl}/sites/${siteId}/crew/crew-manager/bulk/list`,
    },
    workerStatus: {
      list: (siteId: string): string =>
        `${baseBulkUploadUrl}/sites/${siteId}/workers/worker-status/bulk/list`,
    },
    workerLocations: {
      list: (siteId: string): string =>
        `${baseBulkUploadUrl}/sites/${siteId}/workers/location-groups/assign/bulk/list`,
    },
    reportedHours: {
      list: (siteId: string): string =>
        `${baseBulkUploadUrl}/sites/${siteId}/workers/report-hours/bulk/list`,
    },
  },
  grid: {
    create: `${baseUrl}/gridFields`,
    list: (type: GRID_NAMES_TYPE): string => `${baseUrl}/gridFields/${type}`,
  },
  cubejs: {
    load: baseCubejsUrl,
  },
  inventory: {
    get: (floorId: string, inventoryId: string, timestamp?: string): string =>
      `${baseUrl}/floors/${floorId}/inventory/${inventoryId}?timestamp=${timestamp || ''}`,
  },
  maps: {
    workerhistory: baseCubejsUrl,
  },
  workPermit: {
    list: (clientId: string | number, siteId: string | number, floorId: string | number): string =>
      `${baseUrl}/clients/${clientId}/sites/${siteId}/floors/${floorId}/work-permits`,
    create: (
      clientId: string | number,
      siteId: string | number,
      floorId: string | number,
    ): string => `${baseUrl}/clients/${clientId}/sites/${siteId}/floors/${floorId}/work-permits`,
    update: (
      clientId: string | number,
      siteId: string | number,
      floorId: string | number,
      permitId: string | number,
    ): string =>
      `${baseUrl}/clients/${clientId}/sites/${siteId}/floors/${floorId}/work-permits/${permitId}`,
    delete: (
      clientId: string | number,
      siteId: string | number,
      floorId: string | number,
      permitId: string | number,
    ): string =>
      `${baseUrl}/clients/${clientId}/sites/${siteId}/floors/${floorId}/work-permits/${permitId}`,
  },
};
