import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';

type i18nMsgProps = {
  id: string;
  params?: {
    [any: string]: string;
  };
};

export const I18nMsg = ({ id, params }: i18nMsgProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  const transformParams = (): any => {
    if (!params) {
      return {};
    }
    const transformed = {};
    Object.keys(params).forEach((k: string) => {
      transformed[k] = formatMessage({ id: params[k] });
    });
    return transformed;
  };

  return <Fragment>{formatMessage({ id }, transformParams())}</Fragment>;
};
