export interface Site {
  id: string;
  name: string;
  country: string;
  timezone: number | string;
  address?: string;
  notes?: string;
  network?: {
    hw_network_id: string;
    id: string;
  };
  time_zone?: number | string;
}

export interface Sites {
  data: Site[];
  selected: string | null;
  isLoading: boolean;
  isFetched: boolean;
}

export interface Network {
  hw_network_id: string;
  id: string;
}

const initialState: Sites = {
  data: [],
  selected: null,
  isLoading: false,
  isFetched: false,
};

export default initialState;
