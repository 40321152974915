export type MaintanceMapStore = {
  isExpanded: boolean;
  isPresentation: boolean;
  isAnchorGraphView: boolean;
  isImageLoading: boolean;
  isPlayBackMode: boolean;
  playBackTime: any;
  isPlayBackClosed: boolean;
  selectedFloor: any;
  wntInventorys: any;
  searchInventories: string[];
};

const initialState = {
  isExpanded: false,
  isPresentation: false,
  isAnchorGraphView: false,
  isImageLoading: false,
  isPlayBackMode: false,
  playBackTime: null,
  isPlayBackClosed: false,
  selectedFloor: undefined,
  wntInventorys: [],
  searchInventories: [],
};

export default initialState;
