import urls from 'api/urls';
import axios from 'api';

export const getWorkers = async (networkId: number, blueprintId?: number): Promise<any> => {
  const response = await axios.post(urls.safety.workers(), {
    networkId,
    blueprintId,
  });

  return response.data;
};

export const getIncidents = async (
  networkId: string | number,
  pageSize: string | number,
  pageNumber: string | number,
  incidentTypeFilter = '',
): Promise<any> => {
  const response = await axios.get(
    urls.safety.incidents(
      networkId.toString(),
      pageSize.toString(),
      pageNumber.toString(),
      incidentTypeFilter,
    ),
  );
  return response.data;
};

export const postEscalation = async (incidentId: number): Promise<any> => {
  const response = await axios.post(urls.safety.escelate(), {
    incidentId,
  });

  return response.data;
};
