export interface Building {
  name: string;
  id: string;
}

export interface Buildings {
  data: Building[];
  isLoading: boolean;
  selected: string | null;
  isFetched: boolean;
}

const initialState = {
  data: [],
  isLoading: false,
  selected: null,
  isFetched: false,
};

export default initialState;
