import { produce } from 'immer';
import initialState, { WorkPermitsStore } from 'store/state/workPermits';

import {
  CREATE_WORK_PERMIT_FAILED,
  CREATE_WORK_PERMIT_REQ,
  CREATE_WORK_PERMIT_SUCCESS,
  DELETE_WORK_PERMIT_FAILED,
  DELETE_WORK_PERMIT_REQ,
  DELETE_WORK_PERMIT_SUCCESS,
  LIST_WORK_PERMIT_FAILED,
  LIST_WORK_PERMIT_REQ,
  LIST_WORK_PERMIT_SUCCESS,
  SET_SELECTED_WORK_PERMIT,
  UPDATE_WORK_PERMIT_FAILED,
  UPDATE_WORK_PERMIT_REQ,
  UPDATE_WORK_PERMIT_SUCCESS,
} from 'store/actions/actionTypes';
import { IWorkPermit } from 'interfaces/workPermit/IWorkPermit';

export default (state: WorkPermitsStore = initialState, action: any): WorkPermitsStore =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_SELECTED_WORK_PERMIT:
        draft.selectedWorkPermit = action.payload;
        break;
      case LIST_WORK_PERMIT_REQ:
      case CREATE_WORK_PERMIT_REQ:
      case UPDATE_WORK_PERMIT_REQ:
      case DELETE_WORK_PERMIT_REQ:
        draft.loading = true;
        break;
      case LIST_WORK_PERMIT_SUCCESS:
        draft.loading = false;
        draft.data = action.payload;
        break;
      case LIST_WORK_PERMIT_FAILED:
        draft.loading = false;
        draft.data = [];
        break;
      case CREATE_WORK_PERMIT_SUCCESS:
        draft.loading = false;
        draft.data = [...draft.data, action.payload];
        break;
      case UPDATE_WORK_PERMIT_SUCCESS:
        draft.loading = false;
        const objectToUpdate = draft.data.find(
          (workPermit: IWorkPermit) => workPermit.permitId === action.payload.permitId,
        );
        if (objectToUpdate) {
          Object.assign(objectToUpdate, action.payload);
        }
        break;
      case DELETE_WORK_PERMIT_SUCCESS:
        draft.loading = false;
        draft.data = draft.data.filter(
          (workPermit: IWorkPermit) => workPermit.permitId !== action.payload,
        );
        break;
      case CREATE_WORK_PERMIT_FAILED:
      case UPDATE_WORK_PERMIT_FAILED:
      case DELETE_WORK_PERMIT_FAILED:
        draft.loading = false;
        break;
      default:
        break;
    }
  });
