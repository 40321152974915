import { AxiosResponse } from 'axios';
import axios, { CancelToken } from 'api';
import urls from 'api/urls';
import { Floor, CreateFloorData } from 'store/state/floors';
import { USER_CANCELLED } from 'utils/constants';
import { InventoryMeta } from './types';
import { getSiteApiSource } from './sites';

export const cancelListFloorApi = (): void => {
  // @ts-ignore
};

export async function listFloorsApi(
  buildingId: string,
  params = {},
): Promise<AxiosResponse<Floor[]>> {
  cancelListFloorApi();
  const response = await axios.get(urls.floors.list(buildingId), {
    cancelToken: getSiteApiSource().token,
    params: {
      ...params,
    },
  });
  return response.data;
}
export async function createFloorApi(
  buildingId: string,
  data: CreateFloorData,
): Promise<AxiosResponse<Floor>> {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]): void => {
    if (key === 'image_properties' || key === 'distance') {
      Object.entries(value).forEach(([nestedKey, nestedValue]) => {
        // @ts-ignore
        formData.append(`${key}[${nestedKey}]`, `${nestedValue}`.trim());
      });
      return;
    }
    if (key === 'coordinates') {
      Object.entries(value).forEach(([coordinateKey, coodinateValues]) => {
        // @ts-ignore
        Object.entries(coodinateValues).forEach(([latLng, latLngValue]) => {
          // @ts-ignore
          formData.append(`coordinates[${coordinateKey}][${latLng}]`, `${latLngValue}`.trim());
        });
      });
      return;
    }
    formData.append(key, value);
  });
  const response = await axios.post(urls.floors.create(buildingId), formData);
  return response.data;
}

export async function editFloorApi(
  buildingId: string,
  floorId: string,
  data: CreateFloorData,
): Promise<AxiosResponse<{ message: string; status: string }>> {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]): void => {
    if (key === 'image_properties' || key === 'distance') {
      Object.entries(value).forEach(([nestedKey, nestedValue]) => {
        // @ts-ignore
        formData.append(`${key}[${nestedKey}]`, `${nestedValue}`.trim());
      });
      return;
    }
    if (key === 'coordinates') {
      Object.entries(value).forEach(([coordinateKey, coodinateValues]) => {
        // @ts-ignore
        Object.entries(coodinateValues).forEach(([latLng, latLngValue]) => {
          // @ts-ignore
          formData.append(`coordinates[${coordinateKey}][${latLng}]`, `${latLngValue}`.trim());
        });
      });
      return;
    }
    formData.append(key, value);
  });
  const response = await axios.post(urls.floors.edit(buildingId, floorId), formData);
  return response.data;
}

let viewFloorSource = CancelToken.source();

export const cancelViewFloorApi = (): void => viewFloorSource.cancel(USER_CANCELLED);

export async function viewFloorApi(buildingId: string, id: string): Promise<AxiosResponse<Floor>> {
  cancelViewFloorApi();
  viewFloorSource = CancelToken.source();
  const response = await axios.get(urls.floors.view(buildingId, id), {
    cancelToken: viewFloorSource.token,
  });
  return response.data;
}

export async function deleteFloorApi(buildingId: string, id: string): Promise<AxiosResponse<void>> {
  const response = await axios.delete(urls.floors.delete(buildingId, id));
  return response.data;
}

export async function fetchFloorBlueprint(blueprintURL: string): Promise<Blob> {
  // @ts-ignore
  const response = await fetch(blueprintURL, {
    headers: {
      'Cache-Control': 'no-cache',
    },
  });
  // @ts-ignore
  return response.blob() as Promise<Blob>;
}

let floorMetaSource = CancelToken.source();

export const cancelFloorMeta = (): void => floorMetaSource.cancel(USER_CANCELLED);

export async function selectedFloorMetaCountApi(
  floorId: string,
  time: any = {},
): Promise<AxiosResponse<InventoryMeta>> {
  cancelFloorMeta();
  floorMetaSource = CancelToken.source();
  const header: any = {
    cancelToken: floorMetaSource.token,
  };

  if (time.startDate && time.endDate) {
    header.params = {
      startDate: time.startDate,
      endDate: time.endDate,
    };
  }
  const response = await axios.get(urls.floors.inventoryMeta(floorId), header);
  return response.data;
}

// Site Level

export const listAllFloors = async (siteId: string): Promise<AxiosResponse<any>> => {
  const response = await axios.get(urls.floors.allFloors(siteId), {
    cancelToken: getSiteApiSource().token,
  });
  return response.data;
};

export const uploadBulkLocations = async (
  siteId: string,
  data: any[],
): Promise<AxiosResponse<any>> => {
  const response = await axios.post(urls.floors.uploadBulk(siteId), data);
  return response.data;
};

export const fetchLocationBulkTemplate = async (siteId: string): Promise<any> => {
  const response = await axios.get(urls.floors.locationTemplate(siteId), {
    responseType: 'blob',
  });
  return response.data;
};

export const fetchLocationAssignmentBulkTemplate = async (siteId: string): Promise<any> => {
  const response = await axios.get(urls.floors.locationAssignmentTemplate(siteId), {
    responseType: 'blob',
  });
  return response.data;
};

export type LocationGroupRequestBodyType = {
  uuid?: string;
  name: string;
  location_group_tag_id: string;
  blueprint_name: string;
  zone_name?: string;
};

let locationGroupSource = CancelToken.source();
export const bulkUploadLocationGroups = async (
  siteId: string,
  data: LocationGroupRequestBodyType[],
  action = 'draft',
): Promise<AxiosResponse<any>> => {
  const response = await axios.post(urls.floors.uploadBulkLocationGroups(siteId, action), data, {
    cancelToken: locationGroupSource.token,
  });
  return response.data;
};

export const cancelLocationGroupSource = (): void => {
  locationGroupSource.cancel(USER_CANCELLED);
  locationGroupSource = CancelToken.source();
};
