import React from 'react';
import { Col, Row, Space, Typography } from 'antd';
import { I18nMsg } from 'i18n';
import { renderPhoto } from 'ui/components/workers/utils';
import { title } from 'i18n/label';
import { OnlineWorkerType } from 'store/actions/workers';
import WorkerLastseen from 'ui/components/workers/WorkerLastseen';

const { Text } = Typography;

export function hasCoordinates(worker: any): any {
  return (
    worker.Inventory &&
    worker.Inventory.is_active !== null &&
    worker.Inventory.coordinates &&
    worker.Inventory.coordinates.coordinates
  );
}

export const hasOnlineWorkerCoordinates = (worker: OnlineWorkerType): boolean =>
  !!(worker.coordinates && worker.coordinates.coordinates);

export default (feature: any): React.ReactElement => {
  const {
    image,
    worker_tag_id,
    worker_role,
    subcontractor,
    contact_number,
    name,
    id,
    last_seen,
  } = feature.get('details');
  return (
    <Row className="maps-zones__tooltip" wrap={false}>
      <Col className="maps-zones__tooltip__photo">
        <Space>
          <div>{renderPhoto(image, false)}</div>
          <div style={{ width: 165 }}>
            <Row className="maps-zones__tooltip__row" wrap={false}>
              <Col>
                <Text ellipsis strong style={{ fontSize: 12, maxWidth: 160 }} title={name}>
                  {name}
                </Text>
              </Col>
              <Col className="maps-zones__tooltip__flex">
                <div>
                  <Text ellipsis style={{ maxWidth: 70 }} title={worker_tag_id}>
                    {worker_tag_id}
                  </Text>
                </div>
                <div>
                  <Text ellipsis style={{ maxWidth: 90 }} title={contact_number}>
                    {contact_number}
                  </Text>
                </div>
              </Col>
            </Row>
          </div>
        </Space>
      </Col>
      <Col>
        <Row wrap={false} justify="space-between" style={{ padding: '0 5px' }}>
          <Col>
            <Text ellipsis style={{ maxWidth: 100 }} title={worker_role}>
              {worker_role}
            </Text>
          </Col>
          <Col>
            <Text ellipsis style={{ maxWidth: 100 }} title={subcontractor}>
              {subcontractor}
            </Text>
          </Col>
        </Row>
      </Col>
      <Col style={{ padding: '0 5px 5px 5px' }}>
        <Text strong>{<I18nMsg id={title.reported} />}:</Text>
        <WorkerLastseen id={id} lastSeenFromProps={last_seen} />
      </Col>
    </Row>
  );
};
