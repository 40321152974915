import { Client } from '../clients';

export interface UserListItem {
  id: string;
  key?: string;
  name: string;
  user_role: string;
  email: string;
  status: string;
  tagId: string;
}

export interface UserClients {
  isLoading: boolean;
  data: Client[];
  isRequestFailed: boolean;
}

export interface UserList {
  isLoading: boolean;
  data: UserListItem[];
  userClients: UserClients;
  editUser: {
    isLoading: boolean;
    user: UserListItem | undefined;
  };
}

const initialState: UserList = {
  isLoading: false,
  data: [],
  userClients: { isLoading: false, data: [], isRequestFailed: false },
  editUser: {
    isLoading: false,
    user: undefined,
  },
};

export default initialState;
