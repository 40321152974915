import moment from 'moment';
import { Zone } from 'store/state/zones';
import { hasCoordinates } from 'ui/components/MapsZones/MapZoneTooltip';
import { getLastSeen } from 'ui/components/workers/utils';
import { N_A } from 'utils/constants';
import { polygon } from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { Floor } from 'store/state/floors';

type WorkerDetailTypeOnMap = any;

/* eslint-disable */

export const STATUS = {
  active: 'active',
  inactive: 'inactive',
  safe: 'safe',
  autoAcknowledge: 'autoAcknowledge',
  manualAcknowledge: 'manualAcknowledge',
  offline: 'offline',
};

export const colorStyles = {
  [STATUS.manualAcknowledge]: '#AF0D6E',
  [STATUS.autoAcknowledge]: '#743000',
  [STATUS.safe]: '#0001F5',
  [STATUS.active]: '#4caf50',
  [STATUS.inactive]: '#f34436',
};

export const allWorkers = {};

export const getStatus = (
  isSafe: boolean,
  isAcknowledged: string,
  isActive: boolean,
  isEvacuation: boolean,
): string => {
  if (isEvacuation) {
    if (isSafe) {
      return STATUS.safe;
    }
    if (isAcknowledged) {
      return isAcknowledged === 'manual' ? STATUS.manualAcknowledge : STATUS.autoAcknowledge;
    }
  }
  return isActive ? STATUS.active : STATUS.inactive;
};

const isInSafeZone = (coordinates: any, zone: Zone): boolean =>
  zone && booleanPointInPolygon(coordinates, polygon(zone.coordinates.coordinates));

export const ranDomStatus = () => {
  const index = Math.round(Math.random() * 5);
  if (index === 0) {
    return STATUS.manualAcknowledge;
  }
  if (index === 1) {
    return STATUS.autoAcknowledge;
  }
  if (index === 2) {
    return STATUS.safe;
  }
  if (index === 3) {
    return STATUS.safe;
  }
  if (index === 4) {
    return STATUS.active;
  }
  return STATUS.inactive;
};

export const normalizeWorkers = (
  workers: any[],
  zone: Zone,
  evacuationUpdates: any,
  isEvacuation: boolean,
): WorkerDetailTypeOnMap[] =>
  workers.map(
    ({
      id,
      name,
      media_url,
      worker_tag_id,
      contact_number,
      Inventory,
      WorkerRole,
      Subcontractor,
    }: any): WorkerDetailTypeOnMap | any => {
      if (!Inventory) {
        return {};
      }
      const { wnt_inventory_id, is_online, is_active, coordinates } = Inventory;
      const isSafe = isInSafeZone(coordinates, zone);
      const is_acknowledged = evacuationUpdates[wnt_inventory_id];
      const isActive = is_online && is_active;
      const worker: WorkerDetailTypeOnMap = {
        id,
        name,
        worker_tag_id,
        image: {
          url: media_url,
        },
        contact_number: contact_number || N_A,
        status: getStatus(isSafe, is_acknowledged, isActive, isEvacuation),
        coordinates: hasCoordinates({ Inventory }),
        worker_role: WorkerRole ? WorkerRole.name : N_A,
        subcontractor: Subcontractor ? Subcontractor.name || N_A : N_A,
        last_seen: getLastSeen(Inventory) || N_A,
        floorId: Inventory && Inventory.floor_id,
        wnt_inventory_id: Inventory && Inventory.wnt_inventory_id,
        is_acknowledged,
      };
      allWorkers[worker.wnt_inventory_id] = { ...worker };
      return worker;
    },
  );

export const normalizeEventList = (inventory: any): any => {
  const { wnt_inventory_id, safty_event } = inventory;
  if (allWorkers[wnt_inventory_id]) {
    return {
      ...allWorkers[wnt_inventory_id],
      last_seen: moment().format('DD, MMM YYYY HH:mm A'),
      safty_event,
    };
  }
  return null;
};

export const findFloorById = (id: string, floors: Floor[]) =>
  floors.find((floor) => floor.id === id);

type DurationPropsType = {
  startDate: string;
  endDate: string;
};

export const getDurationString = ({ startDate, endDate }: DurationPropsType): string => {
  const start = moment(startDate);
  const end = moment(endDate);
  const duration = end.diff(start, 'seconds');
  let time = ``;

  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);
  const seconds = Math.floor(duration - (hours * 3600 + minutes * 60));

  if (hours) {
    time += `${hours}h `;
  }

  if (minutes) {
    time += `${minutes}m `;
  }

  if (seconds) {
    time += `${seconds}s `;
  }
  return time;
};

export const getEvaluationReports = (time: DurationPropsType) => {
  const duration = getDurationString(time);
  let safeWorkerCount = 0;
  let notRespondedCount = 0;

  Object.values(allWorkers).forEach((w: any) => {
    if (!w.is_acknowledged) {
      notRespondedCount += 1;
    }
    if (w.status === STATUS.safe) {
      safeWorkerCount += 1;
    }
  });

  return {
    duration,
    safeWorkerCount,
    notRespondedCount,
  };
};

export const SOCKET_EVENTS = {
  connected: 'connection',
  disconnected: 'disconnect',
  startEvacuation: 'startEvacuation',
  endEvacuation: 'endEvacuation', // from client
  newtworkFailuer: 'networkFailure',
  safetyServiceError: 'safetyServiceError',
  safetyEvents: 'safetyEvents',
  evacuationInProgress: 'evacuationInProgress',
  evacuating: 'evacuating',
  locationUpdate: 'locationUpdate',
  evacuationEnded: 'evacuationEnded', // from server
};

/**
 *
 *
 */
