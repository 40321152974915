import produce from 'immer';
import initialState from 'store/state/assets';
import { Assets } from 'interfaces/assets';

import {
  ASSETS_RESET_STATUSES_COUNTS,
  ASSETS_SET_STATUSES_COUNTS,
} from 'store/actions/actionTypes';

export default (state: Assets = initialState, action: any): Assets =>
  produce(state, (draft) => {
    switch (action.type) {
      case ASSETS_SET_STATUSES_COUNTS:
        draft.assetsStatesCounts = action.payload;
        break;
      case ASSETS_RESET_STATUSES_COUNTS:
        draft.assetsStatesCounts = initialState.assetsStatesCounts;
        break;
      default:
        break;
    }
  });
