import { State } from 'store/state';
import { WorkerDetailTypeOnMap } from 'store/state/safety';
import { Zone } from 'store/state/zones';

export const selectIsEvacuation = (state: State): boolean => state.safety.isEvacuation;
export const selectIsEvacuationLoading = (state: State): boolean =>
  state.safety.isEvacuationLoading;
export const selectSafeZone = (state: State): Zone => state.safety.safeZone as Zone;
export const selectEvents = (state: State): any => state.safety.safetyEvents;
export const selectSelectedEvent = (state: State): any => state.safety.selectedEvent;
export const selectGateWayId = (state: State): string => state.safety.gateWayId as string;
export const selectIsBlueprintLoaded = (state: State): boolean => state.safety.isBluprintLoaded;
export const selectMapWorkers = (state: State): WorkerDetailTypeOnMap[] => state.safety.workers;
export const selectMapAllWorkers = (state: State): WorkerDetailTypeOnMap[] =>
  state.safety.allWorkers;
export const selectIsWorkersLoading = (state: State): boolean => state.safety.isWorkersLoading;
export const selectFloorIds = (state: State): number[] => state.safety.floorIds;
export const selectZoneIds = (state: State): number[] => state.safety.zoneIds;
export const selectSafeIds = (state: State): number[] => state.safety.assemblyZoneIds;
export const selectevacuationFloorsIds = (state: State): number[] =>
  state.safety.evacuationFloorsIds;
export const selectUpdatedAt = (state: State): string => state.safety.updatedAt;
export const selectStartAt = (state: State): string => state.safety.evacuationStartAt;
export const selectIsWorkersFetched = (state: State): boolean => state.safety.isWorkersFetched;
export const selectEvacuationId = (state: State): string | undefined =>
  state.safety.evacuationSessionId;
