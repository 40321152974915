import produce from 'immer';
import { TOOGLE_SUBCONTRACTORS_FETCH } from 'store/actions/actionTypes';
import { SubContractorsStore, initialState } from 'store/state/subContractors';

export default (state: SubContractorsStore = initialState, action: any): SubContractorsStore =>
  produce(state, (draft) => {
    switch (action.type) {
      case TOOGLE_SUBCONTRACTORS_FETCH:
        draft.isFetchSubcontractors = !draft.isFetchSubcontractors;
        break;
      default:
        break;
    }
  });
