import React, { useEffect } from 'react';
import { Typography } from 'antd';
import { selectMapAllWorkers } from 'store/selectors/safety';
import { useSelector } from 'react-redux';
import moment from 'moment';

const { Text } = Typography;

export default (props: any): React.ReactElement => {
  const { id, lastSeenFromProps } = props;
  const allWorkers = useSelector(selectMapAllWorkers);

  let lastSeen = moment(
    allWorkers.find((w: any) => w.id === id)?.last_seen ?? lastSeenFromProps,
  ).fromNow();

  useEffect(() => {
    lastSeen = moment(allWorkers.find((w: any) => w.id === id)?.last_seen).fromNow();
  }, [allWorkers]);

  return (
    <>
      <Text>{`${lastSeen}`}</Text>
    </>
  );
};
