import produce from 'immer';
import { TOOGLE_WORKER_ROLES_FETCH } from 'store/actions/actionTypes';
import { WorkerRoleStore, initialState } from 'store/state/workerRoles';

export default (state: WorkerRoleStore = initialState, action: any): WorkerRoleStore =>
  produce(state, (draft) => {
    switch (action.type) {
      case TOOGLE_WORKER_ROLES_FETCH:
        draft.isFetchRoles = !draft.isFetchRoles;
        break;
      default:
        break;
    }
  });
