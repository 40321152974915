export interface Workshifts {
  id: string;
  breaks: any[];
  category: string;
  duration_in_seconds: string;
  links: { [x: string]: string };
  name: string;
  non_working_days: number[];
  site_id: string;
  start_time: string;
  end_time: string;
}

export type WorkShiftStore = {
  fetchWorkshifts: boolean;
  data: Workshifts[];
  isLoading: boolean;
};

export const initialState: WorkShiftStore = {
  fetchWorkshifts: false,
  data: [],
  isLoading: false,
};
