export function getFromLocalStorage(key: string): string | null {
  return localStorage.getItem(key);
}

// eslint-disable-next-line
export function setToLocalStorage(key: string, value: any): void {
  return localStorage.setItem(key, JSON.stringify(value));
}

export function removeFromLocalStorage(key: string): void {
  return localStorage.removeItem(key);
}

export function clearLocalStorage(): void {
  return localStorage.clear();
}

window.onstorage = (storageEvent: StorageEvent): void => {
  const { newValue, oldValue, key } = storageEvent;
  if (!key && !oldValue && !newValue) {
    window.location.reload();
    return;
  }
  if (key === 'auth' || key === 'user') {
    if (!oldValue || !newValue || oldValue !== newValue) {
      window.location.reload();
    }
  }
};

export function getLocaleFromStorage(): string {
  const user = getFromLocalStorage('user') as string;
  try {
    return JSON.parse(user).locale;
  } catch {
    return 'en';
  }
}
