import { notification } from 'antd';
import {
  listFloorsApi,
  createFloorApi,
  viewFloorApi,
  deleteFloorApi,
  editFloorApi,
  fetchFloorBlueprint,
} from 'api/floors';
import { Action, ActionCreator } from 'store/types';
import { Dispatch } from 'react';
import { unknowError, USER_CANCELLED } from 'utils/constants';
import { Floor, CreateFloorData } from 'store/state/floors';
import {
  FloorCreateIntersection,
  getIntialValues,
} from 'ui/pages/installation/floors/CreateEditFloor/utils';
import {
  IN__VIEW_FLOOR_REQ,
  IN__VIEW_FLOOR_SUCCESS,
  IN__VIEW_FLOOR_FAILED,
  IN__LIST_FLOORS_REQ,
  IN__LIST_FLOORS_SUCCESS,
  IN__LIST_FLOORS_FAILED,
  IN__CREATE_FLOOR_SUCCESS,
  IN__CREATE_FLOOR_REQ,
  IN__CREATE_FLOOR_FAILED,
  IN__EDIT_FLOOR_SUCESS,
  IN__EDIT_FLOOR_REQ,
  IN__EDIT_FLOOR_FAILED,
  IN__DUPLICATE_FLOOR_REQ,
  IN__DUPLICATE_FLOOR_SUCCESS,
  IN__DUPLICATE_FLOOR_FAILED,
  IN__DELETE_FLOOR_REQ,
  IN__DELETE_FLOOR_SUCCESS,
  IN__DELETE_FLOOR_FAILED,
  IN__SET_FLOOR_TO_EDIT,
  IN__SET_CREATE_EDIT_FLOOR_VISIBLE,
  IN__RESET_FLOORS,
} from 'store/actions/actionTypes';

export type ViewFloorSuccess = Action<string>;
export type ViewFloorFailed = Omit<Action<string>, 'payload'>;
export type ViewFloor = ViewFloorFailed | ViewFloorSuccess;

// @ts-ignore
export function view(buildingId: string, id: string): ActionCreator {
  return async (dispatch: Dispatch<ViewFloor>): Promise<string | void> => {
    dispatch({ type: IN__VIEW_FLOOR_REQ });
    try {
      // const { data: floor } = await viewFloorApi(buildingId, id);
      dispatch({ type: IN__VIEW_FLOOR_SUCCESS, payload: id });
      return id;
    } catch (error) {
      dispatch({ type: IN__VIEW_FLOOR_FAILED });
      error && notification.error({ message: error.response?.data?.message ?? unknowError() });
    }
  };
}

export type ListFloorsSuccess = Action<Floor[]>;
export type ListFloorsFailed = Omit<Action<Floor[]>, 'payload'>;
export type ListFloorsAction = ListFloorsFailed | ListFloorsSuccess;

export function list(
  buildingId: string,
  params: any = {
    sort: 'altitude,desc',
  },
): ActionCreator {
  return async (dispatch: Dispatch<ListFloorsAction | ViewFloor>): Promise<Floor[] | void> => {
    dispatch({ type: IN__LIST_FLOORS_REQ });
    try {
      const { data: floors } = await listFloorsApi(buildingId, params);
      dispatch({ type: IN__LIST_FLOORS_SUCCESS, payload: floors });
      if (floors && floors.length) {
        // @ts-ignore
        dispatch(view(buildingId, floors[0].id));
      }
      return floors;
    } catch (error) {
      if (error && error.message === USER_CANCELLED) {
        return;
      }
      dispatch({ type: IN__LIST_FLOORS_FAILED });
      window.navigator.onLine &&
        error &&
        notification.error({ message: error.response?.data?.message ?? unknowError() });
    }
  };
}

export type CreateFloorSuccess = Action<Floor>;
export type CreateFloorFailed = Omit<Action<Floor>, 'payload'>;
export type CreateFloorAction = CreateFloorSuccess | CreateFloorFailed;

export function create(buildingId: string, data: CreateFloorData): ActionCreator {
  return async (dispatch: Dispatch<CreateFloorAction>): Promise<Floor | void> => {
    dispatch({ type: IN__CREATE_FLOOR_REQ });
    try {
      const { data: floor } = await createFloorApi(buildingId, data);
      dispatch({ type: IN__CREATE_FLOOR_SUCCESS, payload: floor });
      notification.success({ message: 'Floor Created Successfully', duration: 1 });
      return floor;
    } catch (error) {
      dispatch({ type: IN__CREATE_FLOOR_FAILED });
      error && notification.error({ message: error.response?.data?.message ?? unknowError() });
    }
  };
}

export type DuplicateFloorSuccess = Action<Floor>;
export type DuplicateFloorFailed = Omit<Action<Floor>, 'payload'>;
export type DuplicateFloorAction = DuplicateFloorSuccess | DuplicateFloorFailed;

export function duplicate(buildingId: string, floor: Floor): ActionCreator {
  return async (dispatch: Dispatch<DuplicateFloorAction>): Promise<Floor | void> => {
    dispatch({ type: IN__DUPLICATE_FLOOR_REQ });
    try {
      const data = getIntialValues(floor as Floor) as FloorCreateIntersection;
      // const floorId = floor.id;
      // @ts-ignore
      // delete (data as Floor).media_url;
      // const { data: selectedFloor } = await viewFloorApi(buildingId, floorId);
      const bluePrintUrl = (data as Floor).media_url;
      const blueprintBlob = await fetchFloorBlueprint(bluePrintUrl);

      const createFloorFormData = data as CreateFloorData;
      createFloorFormData.blueprint_img = blueprintBlob;

      const { data: floorData } = await createFloorApi(buildingId, createFloorFormData);
      notification.success({ message: 'Floor Duplicated Successfully' });
      dispatch({ type: IN__DUPLICATE_FLOOR_SUCCESS, payload: floorData });

      // view(buildingId, floorData.id)(dispatch);

      return floor;
    } catch (error) {
      dispatch({ type: IN__DUPLICATE_FLOOR_FAILED });
      return notification.error({ message: error.response?.data?.message ?? unknowError() });
    }
  };
}

export type EditFloorAction = Action<any>;

export function edit(buildingId: string, floorId: string, data: CreateFloorData): ActionCreator {
  return async (dispatch: Dispatch<CreateFloorAction>): Promise<any | void> => {
    dispatch({ type: IN__EDIT_FLOOR_REQ, payload: data as any });
    try {
      const response = await editFloorApi(buildingId, floorId, data);
      const { data: floor } = await viewFloorApi(buildingId, floorId);
      // @ts-ignore
      dispatch({ type: IN__EDIT_FLOOR_SUCESS, payload: floor[0] });
      notification.success({ message: 'Floor Updated Successfully', duration: 1 });
      return response;
    } catch (error) {
      dispatch({ type: IN__EDIT_FLOOR_FAILED });
      error && notification.error({ message: error.response?.data?.message ?? unknowError() });
    }
  };
}

export type DeleteFloorSuccess = Action<string>;
export type DeleteFloorFailed = Omit<Action<Floor>, 'payload'>;
export type DeleteFloorAction = DeleteFloorSuccess | DeleteFloorFailed;

export function deleteFloor(buildingId: string, id: string): ActionCreator {
  return async (dispatch: Dispatch<DeleteFloorAction>): Promise<void | string> => {
    dispatch({ type: IN__DELETE_FLOOR_REQ });
    try {
      const response = await deleteFloorApi(buildingId, id);
      dispatch({ type: IN__DELETE_FLOOR_SUCCESS, payload: id });
      notification.success({ message: 'Floor deleted successfully', duration: 1 });
      return `${response.status}`;
    } catch (error) {
      dispatch({ type: IN__DELETE_FLOOR_FAILED });
      error && notification.error({ message: error.response?.data?.message ?? unknowError() });
    }
  };
}

export type ResetSites = Omit<Action<Floor>, 'payload'>;

export function reset(): ActionCreator {
  return (dispatch: Dispatch<ResetSites>): void => {
    dispatch({ type: IN__RESET_FLOORS });
  };
}

export type SetFloorToEditAction = Action<Floor | null>;

export function setFloorToEdit(floor: Floor | null): ActionCreator {
  return (dispatch: Dispatch<SetFloorToEditAction>): void => {
    dispatch({ type: IN__SET_FLOOR_TO_EDIT, payload: floor });
  };
}

export type SetCreateEditFloorVisibleAction = Action<boolean>;
export function setCreateEditFloorVisible(visible: boolean): ActionCreator {
  return (dispatch: Dispatch<SetCreateEditFloorVisibleAction>): void => {
    dispatch({ type: IN__SET_CREATE_EDIT_FLOOR_VISIBLE, payload: visible });
  };
}

export type FloorsActions = ViewFloorSuccess &
  ViewFloorFailed &
  ListFloorsSuccess &
  ListFloorsFailed &
  CreateFloorFailed &
  CreateFloorSuccess &
  DeleteFloorSuccess &
  DeleteFloorFailed;
