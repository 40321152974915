import { ImageDimentions } from 'utils/dom';

export type FormCoordinates = {
  [key in 'a' | 'b' | 'c' | 'd']: {
    [k in 'lat' | 'long']: number;
  };
};

export type Coordinate = number[];

export interface CreateFloorData {
  name: string;
  coordinates: FormCoordinates;
  altitude: string;
  image_properties: ImageDimentions;
  blueprint_img: Blob | string;
  distance: {
    a_b: number;
    b_c: number;
  };
}
export interface Floor extends Omit<CreateFloorData, 'coordinates'> {
  id: string;
  coordinates: {
    type: string;
    coordinates: Coordinate[][];
  };
  media_url: string;
  building_id: string;
  links: { [x: string]: string };
  online_active_worker_count?: number;
  online_in_active_worker_count?: number;
}

export interface Floors {
  data: Floor[];
  isLoading: boolean;
  selected: string | null;
  selectedFloor: Floor | null;
  isCreateEditVisible: boolean;
  floorToEdit: Floor | null;
  isFetched: boolean;
}

const initialState = {
  data: [] as Floor[],
  isLoading: false,
  selected: null,
  selectedFloor: null,
  isCreateEditVisible: false,
  floorToEdit: null,
  isFetched: false,
};

export default initialState;
