export type SideModalState = {
  isOpened: boolean;
  children: any;
  className: string;
};

export const initialState = {
  isOpened: false,
  children: null,
  className: '',
};
