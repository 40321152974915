import { Coordinate } from 'store/state/floors';

export interface Zone {
  id: string;
  name: string;
  coordinates: {
    type: string;
    coordinates: Coordinate[][];
  };
  color: string;
  category: string;
  assembly_point?: boolean;
  floor_id?: number;
}

export interface Zones {
  data: Zone[];
  selected: string | null;
  isLoading: boolean;
  searchType: string;
  filterResult: Zone[];
}

const initialState: Zones = {
  data: [],
  selected: null,
  isLoading: false,
  searchType: '',
  filterResult: [],
};

export default initialState;
