import { combineReducers } from 'redux';
import installationState from 'store/state/installation';
import userManagementState from 'store/state/userManagement';
import { USER_LOGOUT_USER_SUCCESS, USMG__RESET, IN__RESET } from 'store/actions/actionTypes';

import userReducer from './user';
import clientsReducer from './clients';
import sitesReducer from './sites';
import sideModalReducer from './sideModal';
// import installationReducer from './installation';
import userManagemenrReducer from './userManagement';
import workerReducer from './workers';
import buildingReducer from './buildings';
import floorReducer from './floors';
import workshiftReducer from './workshifts';
import crewReducer from './crews';
import maintanceMapReducer from './maintanceMap';
import zoneReducer from './zones';
import wallReducer from './walls';
import anchorReducer from './anchors';
import workerRoles from './workerRoles';
import subContractors from './subContractors';
import safetyReducer from './safety';
import productivityReducer from './productivity';
import dailyAttendanceReportReducer from './dailyAttendanceReport';
import mapsReducer from './maps';
import assetReducer from './assets';
import workPermitReducer from './workPermits';

export const appReducer = combineReducers({
  user: userReducer,
  clients: clientsReducer,
  sites: sitesReducer,
  buildings: buildingReducer,
  floors: floorReducer,
  // installation: installationReducer,
  maintanceMap: maintanceMapReducer,
  userManagement: userManagemenrReducer,
  sideModal: sideModalReducer,
  workers: workerReducer,
  workshifts: workshiftReducer,
  crews: crewReducer,
  zones: zoneReducer,
  walls: wallReducer,
  anchors: anchorReducer,
  workerRoles,
  subcontractors: subContractors,
  safety: safetyReducer,
  productivity: productivityReducer,
  dailyAttendanceReport: dailyAttendanceReportReducer,
  maps: mapsReducer,
  assets: assetReducer,
  workPermits: workPermitReducer,
});

const rootReducer = (state: any, action: any): any => {
  switch (action.type) {
    case USER_LOGOUT_USER_SUCCESS:
      state = undefined;
      break;
    case USMG__RESET:
      state.userManagement = userManagementState;
      break;
    case IN__RESET:
      state.installation = installationState;
      break;
    default:
      break;
  }
  return appReducer(state, action);
};

export default rootReducer;
