import { notification } from 'antd';
import * as Sentry from '@sentry/react';
import { User } from 'store/state/user';
import { Action, ActionCreator } from 'store/types';
import { logoutApi } from 'api/user';
import { setToLocalStorage, clearLocalStorage, getFromLocalStorage } from 'utils/browser-storage';
import { Dispatch } from 'react';
import { unknowError } from 'utils/constants';
import {
  USER_LOGOUT_USER_SUCCESS,
  USER_LANG_SET,
  USER_SET_LOADING,
  USER__LAST_SELECTION,
} from 'store/actions/actionTypes';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export type LoginAction = Action<any>;

export type LoginFormBody = {
  email?: string;
  password?: string;
  group_of_sites_id?: string;
  worker_tag_id?: string;
};

export function login(response: any): ActionCreator {
  return async (dispatch: Dispatch<LoginAction>): Promise<any> => {
    try {
      dispatch({ type: LOGIN, payload: response.data });
      dispatch({
        type: USER__LAST_SELECTION,
        payload: {
          last_client_id: response.data.last_client_id,
          last_site_id: response.data.last_site_id,
        },
      });
      if (response.data) {
        response.data = { ...response.data, locale: 'en' };
      }
      setToLocalStorage('auth', response.data.access_token);
      setToLocalStorage('user', response.data);
      Sentry.setUser({ id: response.data?.id });
    } catch (error) {
      return error;
    }
  };
}

export type LogoutAction = Pick<Action<User>, 'type'>;

export function logout(): ActionCreator {
  return async (dispatch: Dispatch<LogoutAction>): Promise<void> => {
    try {
      await logoutApi();
      dispatch({ type: USER_LOGOUT_USER_SUCCESS });
      clearLocalStorage();
    } catch (error) {
      clearLocalStorage();
    }
  };
}

export type LanguageSetAction = Action<any>;

export function setUserLocale(lang: string): ActionCreator {
  return async (dispatch: Dispatch<LanguageSetAction>): Promise<void> => {
    try {
      const user = JSON.parse(getFromLocalStorage('user') as string);
      user.locale = lang;
      setToLocalStorage('user', user);
      dispatch({ type: USER_LANG_SET, payload: lang });
    } catch (error) {
      error &&
        notification.error({
          message: error.response?.data?.message ?? unknowError(),
        });
    }
  };
}

export const AUTHENTICATE = 'AUTHENTICATE';
export type AuthenticateAction = Action<User>;

export type UserLoading = Action<boolean>;

export function userLoading(loading: boolean): ActionCreator {
  return async (dispatch: Dispatch<UserLoading>): Promise<void> => {
    dispatch({ type: USER_SET_LOADING, payload: loading });
  };
}

export type UserActions = LoginAction & LogoutAction & AuthenticateAction;
