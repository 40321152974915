import produce from 'immer';
import { TOOGLE_CREWS_FETCH } from 'store/actions/actionTypes';
import { CrewsStore, initialState } from 'store/state/crews';

export default (state: CrewsStore = initialState, action: any): CrewsStore =>
  produce(state, (draft) => {
    switch (action.type) {
      case TOOGLE_CREWS_FETCH:
        draft.fetchCrews = !draft.fetchCrews;
        break;
      default:
        break;
    }
  });
