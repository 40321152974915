import { Floor, CreateFloorData, Coordinate } from 'store/state/floors';
import { Rule } from 'antd/lib/form';

export function getIntialValues(floor: Floor | null): FloorCreateIntersection | null {
  if (!floor) return null;
  const formValues = { ...floor } as any;
  delete formValues.id;
  delete formValues.building_id;
  delete formValues.links;

  const { coordinates } = floor.coordinates;
  formValues.coordinates = {
    a: {
      lat: coordinates[0][0][1],
      long: coordinates[0][0][0],
    },
    b: {
      lat: coordinates[0][1][1],
      long: coordinates[0][1][0],
    },
    c: {
      lat: coordinates[0][2][1],
      long: coordinates[0][2][0],
    },
    d: {
      lat: coordinates[0][3][1],
      long: coordinates[0][3][0],
    },
  };
  formValues.category = formValues.category ?? undefined;
  return formValues as FloorCreateIntersection;
}

export type FloorCreateIntersection = Floor | CreateFloorData;

export const latLongRules: { [key in 'lat' | 'long']: Rule[] } = {
  lat: [
    {
      required: true,
      whitespace: true,
      message: 'Latitude is mandatory',
    },
    {
      type: 'number',
      message: 'Latitude should be number between -90 and 90',
      min: -90,
      max: 90,
      transform: (value): number => Number(value),
    },
  ],
  long: [
    {
      required: true,
      whitespace: true,
      message: 'Longitude is mandatory',
    },
    {
      type: 'number',
      min: -180,
      max: 180,
      message: 'Longitude should be number between -180 and 180',
      transform: (value): number => Number(value),
    },
  ],
};

function toRadians(deg: number): number {
  return deg * (Math.PI / 180);
}

function roundTwoDecimals(number: number): number {
  return Number(number.toString().substring(0, number.toString().indexOf('.') + 3));
}

export function findDistance(point1: Coordinate, point2: Coordinate): number {
  const [lat1, lon1] = point1;
  const [lat2, lon2] = point2;
  const R = 6371e3; // metres
  const φ1 = toRadians(lat1);
  const φ2 = toRadians(lat2);
  const Δφ = toRadians(lat2 - lat1);
  const Δλ = toRadians(lon2 - lon1);

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c;
  return d;
}

export function isValidDistance(
  point1: Coordinate,
  point2: Coordinate,
  givenDistance: number,
): boolean {
  const d = findDistance(point1, point2);
  console.table([
    {
      point1: `(${point1.join(',')})`,
      point2: `(${point2.join(',')})`,
      givenDistance,
      calculatedDistance: d,
    },
  ]);
  return roundTwoDecimals(d) === roundTwoDecimals(givenDistance);
}

export const coordinatesKeys = [
  ['coordinates', 'a', 'lat'],
  ['coordinates', 'a', 'long'],

  ['coordinates', 'b', 'lat'],
  ['coordinates', 'b', 'long'],

  ['coordinates', 'c', 'lat'],
  ['coordinates', 'c', 'long'],

  ['coordinates', 'd', 'lat'],
  ['coordinates', 'd', 'long'],
];
