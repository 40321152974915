import { produce } from 'immer';
import initialState, { Sites } from 'store/state/sites';
import {
  SITES__VIEW_SITE,
  SITES__LIST_SITES_REQ,
  SITES__LIST_SITES_SUCCESS,
  SITES__LIST_SITES_FAILED,
  SITES__CREATE_SITE_SUCCESS,
} from 'store/actions/actionTypes';
import { SiteActions } from 'store/actions/sites';
import momentTimeZone from 'moment-timezone';
import moment from 'moment';

const sitesReducer = (state: Sites = initialState, action: SiteActions): Sites =>
  produce(state, (draft) => {
    switch (action.type) {
      case SITES__LIST_SITES_REQ:
        draft.isLoading = true;
        draft.selected = null;
        draft.isFetched = false;
        draft.data = [];
        break;
      case SITES__LIST_SITES_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        draft.isFetched = true;
        break;
      case SITES__VIEW_SITE:
        draft.selected = action.payload;
        const site = draft.data.find((s) => `${s.id}` === `${action.payload}`);
        momentTimeZone.tz.setDefault(site?.timezone as string);
        moment.relativeTimeThreshold('ss', 0);
        break;
      case SITES__CREATE_SITE_SUCCESS:
        draft.data.unshift(action.payload);
        draft.data.sort((a, b) => (a.name > b.name ? 1 : -1));
        draft.selected = action.payload.id;
        break;
      case SITES__LIST_SITES_FAILED:
        draft.isLoading = false;
        draft.isFetched = true;
        break;
      default:
        break;
    }
  });

export default sitesReducer;
