export interface User {
  isAuthenticated: boolean;
  name: string | null;
  email: string | null;
  id: string | null;
  role: string | null;
  permissions: string[];
  hasPermission: (permission: string) => boolean;
  isSuperAdmin: () => boolean;
  locale: 'en' | 'ar';
  isLoading: boolean;
  last_client_id: string | null;
  last_site_id: string | null;
  worker_details?: {
    worker_tag_id: string | null;
  };
  user_role?: string;
  status?: string;
}

export const USER_PERMISSIONS = {
  CLIENTS_CREATE: 'clients:create',
};

const initialState: User = {
  isAuthenticated: false,
  name: null,
  email: null,
  id: null,
  role: null,
  permissions: [],
  hasPermission(permissionName: string): boolean {
    return this.permissions.includes(permissionName);
  },
  isSuperAdmin(): boolean {
    return this.role === 'super-admin';
  },
  locale: 'en',
  isLoading: false,
  last_client_id: null,
  last_site_id: null,
};

export default initialState;
