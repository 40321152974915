export interface Client {
  id: string;
  name: string;
  address?: string;
}

export interface Clients {
  data: Client[];
  selected: string | null;
  isLoading: boolean;
}

const initialState: Clients = {
  data: [],
  selected: null,
  isLoading: false,
};

export default initialState;
