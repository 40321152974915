import produce from 'immer';
import initialState, { UserList, UserListItem } from 'store/state/userManagement';
import { UserManagementActions } from 'store/actions/userManagement';
import {
  USMG__USERS_LIST_REQ,
  USMG__USERS_LIST_SUCCESS,
  USMG__USERS_LIST_FAILED,
  USMG__DEACTIVATE_USER_REQ,
  USMG__DEACTIVATE_USER_SUCCESS,
  USMG__DEACTIVATE_USER_FAILED,
  USMG__ACTIVATE_USER_REQ,
  USMG__ACTIVATE_USER_SUCCESS,
  USMG__ACTIVATE_USER_FAILED,
  USMG__RESEND_INVITATION_REQ,
  USMG__RESEND_INVITATION_SUCCESS,
  USMG__RESEND_INVITATION_FAILED,
  USMG__EDIT_USER_REQ,
  USMG__EDIT_USER_SUCCESS,
  USMG__EDIT_USER_FAILED,
  USMG__CREATE_USER_SUCCESS,
  USMG__USER_CLIENT_LIST_REQ,
  USMG__USER_CLIENT_LIST_SUCCESS,
  USMG__USER_CLIENT_LIST_FAILED,
  USMG__CREATE_USER_REQ,
} from 'store/actions/actionTypes';
import { User } from 'store/state/user';

const LIST_FLOORS_REQ = 'LIST_FLOORS_REQ';

const userManagement = (state = initialState, action: UserManagementActions): UserList =>
  produce(state, (draft) => {
    switch (action.type) {
      case LIST_FLOORS_REQ:
      case USMG__USERS_LIST_REQ:
      case USMG__RESEND_INVITATION_REQ:
        draft.isLoading = true;
        break;
      case USMG__USERS_LIST_FAILED:
      case USMG__DEACTIVATE_USER_FAILED:
      case USMG__ACTIVATE_USER_FAILED:
      case USMG__RESEND_INVITATION_FAILED:
      case USMG__RESEND_INVITATION_SUCCESS:
      case USMG__EDIT_USER_FAILED:
        draft.isLoading = false;
        break;
      case USMG__USERS_LIST_SUCCESS:
        draft.isLoading = false;
        draft.data = action.payload.map((user: UserListItem) => ({ ...user, key: user.id }));
        break;
      // Update userList Will add Later
      case USMG__DEACTIVATE_USER_SUCCESS:
      case USMG__ACTIVATE_USER_SUCCESS:
        draft.editUser.isLoading = false;
        draft.editUser.user = action.payload;
        break;
      case USMG__CREATE_USER_REQ:
      case USMG__EDIT_USER_REQ:
      case USMG__DEACTIVATE_USER_REQ:
      case USMG__ACTIVATE_USER_REQ:
        draft.editUser.isLoading = true;
        draft.editUser.user = undefined;
        break;
      case USMG__CREATE_USER_SUCCESS:
      case USMG__EDIT_USER_SUCCESS: {
        const formData = action.payload as User;
        draft.editUser.isLoading = false;
        // @ts-ignore
        draft.editUser.user = formData;
        break;
      }
      case USMG__USER_CLIENT_LIST_REQ: {
        draft.userClients.isLoading = true;
        draft.userClients.data = [];
        break;
      }
      case USMG__USER_CLIENT_LIST_SUCCESS: {
        draft.userClients.data = action.payload;
        draft.userClients.isLoading = false;
        draft.userClients.isRequestFailed = false;
        break;
      }
      case USMG__USER_CLIENT_LIST_FAILED: {
        draft.userClients.isLoading = false;
        draft.userClients.isRequestFailed = true;
        break;
      }
      default:
        break;
    }
  });

export default userManagement;
