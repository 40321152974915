import moment from 'moment';
import { produce } from 'immer';
import {
  SET_IS_SAFETY_EVACUATION,
  SET_SAFE_ZONE,
  SET_SAFETY_EVENTS,
  SET_SELECTED_EVENT,
  RESET_SAFETY_STORE,
  SET_GATEWAY_ID,
  SET_MAP_BLUEPRINT_LOADING,
  SET_MAP_WORKERS,
  SET_MAP_WORKERS_LOADING,
  SET_MAP_ALL_WORKERS,
  SET_MAP_FILTERED_FLOOR,
  SET_MAP_EVACUATION_PROGRESS,
  SET_MAP_FILTERED_ZONE,
  SET_MAP_EVACUATION_STARTAT,
  SET_MAP_EVACUATION_LOADING,
  SET_MAP_EVACUATION_ID,
  SET_MAP_LOCATION_UPDATE,
  SET_SELECTED_SAFETY_INCIDENT,
  SET_MAP_FILTERED_ASSEMBLY_ZONE,
  SET_MAP_EVACUATION_FLOORS_IDS,
} from 'store/actions/actionTypes';
import { SafetyActions, normalizeWorkers } from 'store/actions/safety';
import { initialState, SafetyState } from 'store/state/safety';
import { STATUS } from 'ui/components/Safety/MapView/utils';

const getStatus = (worker: any, isEvacuation = false): string => {
  if (isEvacuation) {
    if (worker.is_online === false) {
      return STATUS.offline;
    }
    if (worker.isSafe) {
      return STATUS.safe;
    }
    if (worker.isWorkerAcknowledged) {
      return STATUS.manualAcknowledge;
    }
    return STATUS.autoAcknowledge;
  }
  return worker.is_active ? STATUS.active : STATUS.inactive;
};

const safetyReducer = (state: SafetyState = initialState, action: SafetyActions): SafetyState =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_IS_SAFETY_EVACUATION:
        draft.isEvacuation = action.payload;
        draft.allWorkers.forEach((w) => {
          if (action.payload) {
            w.status = getStatus(w, w.isIncludedEvacuation);
          } else {
            w.isIncludedEvacuation = false;
            w.status = getStatus(w, action.payload);
          }
        });
        break;
      case SET_SAFE_ZONE:
        draft.safeZone = action.payload;
        break;
      case SET_SAFETY_EVENTS:
        if (action.payload) {
          draft.safetyEvents.unshift(action.payload);
        } else {
          draft.safetyEvents = [];
        }
        break;
      case SET_SELECTED_EVENT: {
        let worker = null;
        if (action.payload && action.payload.id) {
          worker = draft.allWorkers.find((w) => w.id === action.payload.id);
        }
        draft.selectedEvent = worker;
        break;
      }
      case SET_SELECTED_SAFETY_INCIDENT: {
        let worker = null;
        if (action.payload && action.payload.worker_id) {
          worker = draft.allWorkers.find((w) => w.id === action.payload.worker_id);
        }
        draft.selectedEvent = worker;
        break;
      }
      case SET_GATEWAY_ID:
        draft.gateWayId = action.payload;
        break;
      case SET_MAP_BLUEPRINT_LOADING:
        draft.isBluprintLoaded = action.payload;
        break;
      case SET_MAP_EVACUATION_LOADING:
        draft.isEvacuationLoading = action.payload;
        break;
      case SET_MAP_WORKERS:
        draft.workers = action.payload;
        break;
      case SET_MAP_WORKERS_LOADING:
        draft.isWorkersLoading = action.payload;
        break;
      case SET_MAP_FILTERED_FLOOR:
        draft.floorIds = action.payload;
        break;
      case SET_MAP_FILTERED_ZONE:
        draft.zoneIds = action.payload;
        break;
      case SET_MAP_FILTERED_ASSEMBLY_ZONE:
        draft.assemblyZoneIds = action.payload;
        break;
      case SET_MAP_EVACUATION_FLOORS_IDS:
        draft.evacuationFloorsIds = action.payload || [];
        break;
      case SET_MAP_EVACUATION_PROGRESS:
      case SET_MAP_LOCATION_UPDATE:
        const workers: any = action.payload;

        draft.updatedAt = moment.utc().format();

        Object.keys(workers).forEach((workerId) => {
          const updateWorker: any = draft.allWorkers.find((w) => w.id.toString() === workerId);
          if (updateWorker) {
            updateWorker.coordinates = workers[workerId].coordinates;
            updateWorker.floor_id = workers[workerId].blueprint_id;
            updateWorker.last_seen = workers[workerId].last_seen || '-NA-';
            updateWorker.blueprint_name = workers[workerId].blueprint_name;
            updateWorker.zone_name = workers[workerId].zone_name;
            updateWorker.is_active = workers[workerId].is_active;
            updateWorker.is_online = workers[workerId].is_online;
            if (action.type === 'Safety: Evacuation in progress') {
              updateWorker.isIncludedEvacuation = workers[workerId].isIncludedEvacuation;
              updateWorker.status = getStatus(
                workers[workerId],
                workers[workerId].isIncludedEvacuation,
              );
            } else {
              updateWorker.isIncludedEvacuation = !!updateWorker.isIncludedEvacuation;
              updateWorker.status = getStatus(
                workers[workerId],
                !!updateWorker.isIncludedEvacuation,
              );
            }
          } else if (workers[workerId].is_online) {
            draft.allWorkers.push({
              ...normalizeWorkers([workers[workerId]])[0],
              coordinates: workers[workerId].coordinates,
              status: getStatus(workers[workerId], draft.isEvacuation),
              floor_id: workers[workerId].blueprint_id,
              last_seen: workers[workerId].last_seen || '-NA-',
              blueprint_name: workers[workerId].blueprint_name,
              zone_name: workers[workerId].zone_name,
              is_active: workers[workerId].is_active,
              is_online: workers[workerId].is_online,
            });
          }
        });
        // console.log('Evacuation Status Ended');
        break;
      case SET_MAP_EVACUATION_STARTAT:
        draft.evacuationStartAt = action.payload;
        break;
      case SET_MAP_EVACUATION_ID:
        draft.evacuationSessionId = action.payload;
        break;
      case RESET_SAFETY_STORE:
        draft.isEvacuation = initialState.isEvacuation;
        draft.safeZone = initialState.safeZone;
        draft.safetyEvents = initialState.safetyEvents;
        draft.selectedEvent = initialState.selectedEvent;
        draft.gateWayId = initialState.gateWayId;
        draft.isBluprintLoaded = initialState.isBluprintLoaded;
        draft.workers = initialState.workers;
        draft.isWorkersLoading = initialState.isWorkersLoading;
        draft.updatedAt = initialState.updatedAt;
        draft.allWorkers = initialState.allWorkers;
        draft.floorIds = initialState.floorIds;
        draft.zoneIds = initialState.zoneIds;
        draft.assemblyZoneIds = initialState.assemblyZoneIds;
        draft.evacuationStartAt = initialState.evacuationStartAt;
        draft.isWorkersFetched = initialState.isWorkersFetched;
        draft.evacuationFloorsIds = initialState.evacuationFloorsIds;
        break;
      case SET_MAP_ALL_WORKERS:
        draft.allWorkers = action.payload;
        draft.isWorkersFetched = true;
        draft.updatedAt = moment.utc().format();
        break;
      default:
        break;
    }
  });

export default safetyReducer;
