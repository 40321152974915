/* eslint-disable no-param-reassign */
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { notification } from 'antd';
import { getFromLocalStorage, clearLocalStorage } from 'utils/browser-storage';
import { NOT_AUTHORIZED, BAD_CREDENTIALS } from 'utils/httpCodes';
import { renderTemplateString } from 'utils/helpers';
import { USER_CANCELLED } from 'utils/constants';

export const { CancelToken } = Axios;

const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_ROOT_URL,
});

const isNetworkError = (error: any): boolean => {
  return !error.response;
};

const wasLoginRequest = (request: XMLHttpRequest): boolean => {
  return request && request.responseURL.includes('/login');
};

const redirectTo403 = (): void => {
  window.location.href = '/403';
};
const redirectToLogin = (): void => {
  window.location.href = '/login';
};

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const { urlParams, url = '' } = config;
    if (urlParams) {
      config.url = renderTemplateString(url, urlParams);
    }
    if (url.startsWith(process.env.REACT_APP_ROOT_URL!)) {
      config = {
        ...config,
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${JSON.parse(getFromLocalStorage('auth') as string)}`,
        },
      };

      /* No Cache Header for all new Bulk Upload Apis */
      if (url.startsWith(`${process.env.REACT_APP_ROOT_URL!}/bulk`) && config.headers) {
        config.headers['Cache-Control'] =
          'no-store, no-cache, max-age=0, must-revalidate, proxy-revalidate, private';
      }
    }
    return config;
  },
);

axiosInstance.interceptors.response.use(
  (config): AxiosResponse => config,
  (error): Promise<any> => {
    if (error.message === USER_CANCELLED) {
      // eslint-disable-next-line
      return Promise.reject({ message: USER_CANCELLED });
    }
    if (isNetworkError(error)) {
      // notification.destroy();
      // notification.error({ message: noInternetMessage(), duration: 1 });
      return Promise.reject();
    }
    const { status } = error.response;
    const token = getFromLocalStorage('auth');
    if (status === BAD_CREDENTIALS && !wasLoginRequest(error.request)) {
      if (token) {
        clearLocalStorage();
        notification.destroy();
        notification.error({
          message: 'Bad Credentials',
          onClose: redirectToLogin,
          duration: 1,
        });
        return Promise.reject();
      }
      // eslint-disable-next-line
      return Promise.reject({ message: USER_CANCELLED });
    }
    if (status === NOT_AUTHORIZED) {
      notification.error({ message: 'Not Authorized', onClose: redirectTo403, duration: 1 });
      return Promise.reject();
    }
    return Promise.reject(error);
  },
);
export const cubeJSInstance = Axios.create({
  baseURL: process.env.REACT_APP_CUBEJS_ROOT_URL || 'https://wakecap.azure-api.net',
});

cubeJSInstance.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const { urlParams, url = '' } = config;
    if (urlParams) {
      config.url = renderTemplateString(url, urlParams);
    }
    config = {
      // @ts-ignore
      retry: 4,
      ...config,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: `Bearer ${process.env.REACT_APP_NEW_ROOT_TOKEN ||
          JSON.parse(getFromLocalStorage('auth') as string)}`,
      },
    };
    return config;
  },
);

cubeJSInstance.interceptors.response.use(
  (config: any): Promise<AxiosRequestConfig> => {
    if (
      config &&
      config.config &&
      config.data &&
      config.data.error === 'Continue wait' &&
      config.config.retry
    ) {
      config.config.retry -= 1;
      return new Promise((resolve) =>
        setTimeout(() => resolve(cubeJSInstance((config as any).config)) as any, 2000),
      );
    }
    return config;
  },
  (error): Promise<any> => {
    if (error && error.config && error.config.retry) {
      error.config.retry -= 1;
      return cubeJSInstance(error.config);
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
