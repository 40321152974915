import React, { ReactElement, Fragment } from 'react';
import { UserDefaultPhotoSquare, NoHelemtColorIcon } from 'utils/icons';
import { Badge, Avatar, Space, Typography } from 'antd';
import Icon from '@ant-design/icons';
import moment from 'moment';
import { convertSiteToUtcToday, convertUtcTodayToSite } from 'utils/timezones';
import { getFileSizeInMB } from 'utils/helpers';
import '../WorkersList/WorkerList.less';

export const getStatusClr = (status: string): string => {
  switch (status) {
    case 'active':
      return '#4CAF50';
    case 'inactive':
      return '#F34436';
    default:
      return '#b3bdcc';
  }
};

export const renderPhoto = (data: any, isBadge = true): React.ReactElement => {
  const { is_online, url } = data?.value ? data?.value : data;
  if (is_online && isBadge) {
    return (
      <Badge color={getStatusClr(is_online)}>
        <Avatar
          src={url}
          shape="square"
          icon={
            <Icon
              component={(): ReactElement => <UserDefaultPhotoSquare width={32} height={32} />}
            />
          }
        ></Avatar>
      </Badge>
    );
  }
  return (
    <Avatar
      src={url}
      shape="square"
      icon={
        <Icon component={(): ReactElement => <UserDefaultPhotoSquare width={32} height={32} />} />
      }
    ></Avatar>
  );
};

export const renderWorkerPhoto = (data: any, isBadge = true): React.ReactElement => {
  const { is_online, url } = data?.data?.image || {};
  if (is_online && isBadge) {
    return (
      <Badge color={getStatusClr(is_online)}>
        <Avatar
          src={url}
          shape="square"
          icon={
            <Icon
              component={(): ReactElement => <UserDefaultPhotoSquare width={32} height={32} />}
            />
          }
        ></Avatar>
      </Badge>
    );
  }
  return (
    <Avatar
      src={url}
      shape="square"
      icon={
        <Icon component={(): ReactElement => <UserDefaultPhotoSquare width={32} height={32} />} />
      }
    ></Avatar>
  );
};
export const getStatus = (is_online: any, is_active: any): string | null => {
  if (is_online === null || is_online === undefined) {
    return null;
  }
  // eslint-disable-next-line
  return is_online ? (is_active ? 'active' : 'inactive') : 'offline';
};

export const getWorkerStatus = (is_online: any, is_active: any): string => {
  if (is_online === null || is_online === undefined) {
    return '-NA-';
  }
  // eslint-disable-next-line
  return is_online ? (is_active ? 'active' : 'inactive') : 'offline';
};

export const getLastSeen = (last_seen: any): string => {
  return last_seen && moment(last_seen).fromNow();
};

export const getDate = (date: string): string => {
  return date && moment(date).format('YYYY-MM-DD');
};

export const getLastActive = (last_active_at: any): string => {
  return last_active_at && moment(last_active_at).fromNow();
};

const getAssignedCrew = (crews: any, naText: string): string => {
  const today = moment()
    .startOf('day')
    .format('YYYY-MM-DD');
  let assigned;
  for (let i = 0; i < crews.length; i += 1) {
    const date = moment(crews[i].assigned_at)
      .startOf('day')
      .format('YYYY-MM-DD');
    if (date <= today) {
      assigned = crews[i];
    }
  }
  return assigned ? assigned.crew_name : naText;
};

const getAssignedWorkshift = (workshifts: any, naText: string): any => {
  const today = moment()
    .startOf('day')
    .format('YYYY-MM-DD');
  let assigned;
  for (let i = 0; i < workshifts.length; i += 1) {
    const date = moment(workshifts[i].assigned_at)
      .startOf('day')
      .format('YYYY-MM-DD');
    if (date <= today) {
      assigned = workshifts[i];
    }
  }
  return assigned ? assigned?.workshift_name : naText;
};

export const normalizeWorkerDetails = (
  {
    id,
    media_url,
    name,
    site_id,
    worker_tag_id,
    contact_number,
    helmet_color,
    created_at,
    inventory_id,
    updated_at,
    inventory_assigned_at,
    is_online,
    is_active,
    worker_role_name,
    wnt_inventory_id,
    building_name,
    floor_name,
    zone_name,
    last_seen,
    last_active_at,
    subcontractor_name,
    crew_name,
    workshift_name,
  }: any,
  naText: string,
): any => ({
  id,
  inventory_id,
  image: {
    url: media_url,
    is_online: getStatus(is_online, is_active),
  },
  status: getWorkerStatus(is_online, is_active),
  name,
  site_id,
  worker_tag_id,
  worker_role: worker_role_name || naText,
  subcontractor: subcontractor_name || naText,
  helmet: {
    color: helmet_color,
    wnt_inventory_id: wnt_inventory_id || naText,
  },
  building: building_name || naText,
  floor: floor_name || naText,
  zone: zone_name || naText,
  last_seen: getLastSeen(last_seen) || naText,
  last_active: getLastActive(last_active_at) || naText,
  key: `worker-${id}`,
  contact_number: contact_number || naText,
  crew_name: crew_name || naText,
  workshift_name: workshift_name || naText,
  created_at: getDate(created_at) || naText,
  updated_at: getDate(updated_at) || naText,
  inventory_assigned_at: getDate(inventory_assigned_at) || naText,
});

export const normalizeWorkersList = (data: any[], naText: string): any[] => {
  if (!data || !data.length) {
    return [];
  }
  return data.map((worker) => normalizeWorkerDetails(worker, naText));
};

export const renderHelementId = (data: any): React.ReactElement => {
  let helmet: any;
  if (!data.color && !data.wnt_inventory_id && !data.data) helmet = '';
  else helmet = data.color || data.wnt_inventory_id ? data : data.data.helmet;

  return (
    <Fragment>
      {helmet !== '' && (
        <Space align="center">
          {helmet.color ? (
            <div
              className="workers-table__container__color-btn"
              style={{ backgroundColor: helmet.color }}
            ></div>
          ) : (
            <NoHelemtColorIcon />
          )}
          <Typography.Text>
            <span>{helmet.wnt_inventory_id}</span>
          </Typography.Text>
        </Space>
      )}
    </Fragment>
  );
};

export const renderSiteTime = (crews: any[], crewId: number): string => {
  if (!crews || !crews.length) {
    return '';
  }
  const dates = crews.filter((c) => c.crew_id === crewId).map((d) => d.assigned_at);
  if (dates.length) {
    if (dates.length === 1) {
      return convertUtcTodayToSite(dates[0]).format('DD/MM/YYYY');
    }
    const today = convertSiteToUtcToday();
    for (let i = 0; i < dates.length; i += 1) {
      const date = convertSiteToUtcToday(dates[i]);
      if (date === today) {
        return convertUtcTodayToSite(dates[i]).format('DD/MM/YYYY');
      }
      const isActive = date > today;
      if (isActive) {
        return convertUtcTodayToSite(dates[i - 1]).format('DD/MM/YYYY');
      }
    }
    return convertUtcTodayToSite(dates[dates.length - 1]).format('DD/MM/YYYY');
  }
  return '';
};

export const normalizeAssigned = (data: any, naText: string): any => ({
  ...data,
  assignedCrew: getAssignedCrew(data.AssignedCrews, naText),
  assignedWorkshift: getAssignedWorkshift(data.AssignedWorkshifts, naText),
});

/**
 * Upload Excel Utils
 */

export const excelUploadProps = (handleSelectExcel: any): any => ({
  name: 'file',
  multiple: false,
  onChange: handleSelectExcel,
  accept: '.xlsx',
  showUploadList: false,
  beforeUpload: (): boolean => false,
});

export function acceptedExtentions(file: File, types: string[]): boolean {
  if (types.includes(file.type)) {
    return true;
  }
  return false;
}

export function validateImageSize(file: File, types: string[]): number {
  if (!acceptedExtentions(file, types)) {
    return 1;
  }
  if (getFileSizeInMB(file) > 10) {
    return 2;
  }
  return 0;
}

export const getStatusFromInventory = (inventory: any): string | null => {
  if (!inventory) {
    return null;
  }
  // eslint-disable-next-line
  return inventory.is_online ? (inventory.is_active ? 'active' : 'inactive') : 'offline';
};

export const getWorkerStatusFromInventory = (inventory: any): string => {
  if (!inventory) {
    return '-NA-';
  }
  // eslint-disable-next-line
  return inventory.is_online ? (inventory.is_active ? 'active' : 'inactive') : 'offline';
};

export const getHelmet = (inventory: any): string => inventory && inventory.wnt_inventory_id;

export const getBuilding = (inventory: any): string =>
  inventory && inventory.Building && inventory.Building.name;

export const getFloor = (inventory: any): string =>
  inventory && inventory.Floor && inventory.Floor.name;

export const getZone = (inventory: any): string =>
  inventory && inventory.Zone && inventory.Zone.name;

export const getLastSeenFromInventory = (inventory: any): string => {
  return inventory && inventory.last_seen && moment(inventory.last_seen).fromNow();
};

export const getLastActiveFromInventory = (inventory: any): string => {
  return inventory && inventory.last_active_at && moment(inventory.last_active_at).fromNow();
};

export const normalizeWorkerDetailsV2 = (
  {
    id,
    media_url,
    name,
    site_id,
    worker_tag_id,
    WorkerRole,
    contact_number,
    helmet_color,
    Inventory,
    Subcontractor,
    created_at,
    inventory_id,
    AssignedCrews,
    AssignedWorkshifts,
    updated_at,
    inventory_assigned_at,
  }: any,
  naText: string,
): any => ({
  id,
  inventory_id,
  Inventory,
  AssignedCrews,
  AssignedWorkshifts,
  image: {
    url: media_url,
    is_online: getStatusFromInventory(Inventory),
  },
  status: getWorkerStatusFromInventory(Inventory),
  name,
  site_id,
  worker_tag_id,
  worker_role: WorkerRole ? WorkerRole.name : naText,
  subcontractor: Subcontractor ? Subcontractor.name || naText : naText,
  helmet: {
    color: helmet_color,
    wnt_inventory_id: getHelmet(Inventory) || naText,
  },
  building: getBuilding(Inventory) || naText,
  floor: getFloor(Inventory) || naText,
  zone: getZone(Inventory) || naText,
  last_seen: getLastSeenFromInventory(Inventory) || naText,
  last_active: getLastActiveFromInventory(Inventory) || naText,
  key: `worker-${id}`,
  contact_number: contact_number || naText,
  crew_name: AssignedCrews ? getAssignedCrew(AssignedCrews, naText) : naText,
  workshift_name: AssignedWorkshifts ? getAssignedWorkshift(AssignedWorkshifts, naText) : naText,
  created_at: getDate(created_at) || naText,
  updated_at: getDate(updated_at) || naText,
  inventory_assigned_at: getDate(inventory_assigned_at) || naText,
});

export const normalizeWorkersListV2 = (data: any[], naText: string): any[] => {
  if (!data || !data.length) {
    return [];
  }
  return data.map((worker) => normalizeWorkerDetailsV2(worker, naText));
};
