import moment from 'moment';

export function dateFormater(str: string): string | null {
  if (!str) {
    return null;
  }
  /* eslint-disable */
  const [dd, mm, yyyy] = str.split(/(\-|\/)/g).filter((a: string) => {
    // @ts-ignore
    return !isNaN(a);
  });
  return `${+dd < 10 ? `0${+dd}` : dd}/${+mm < 10 ? `0${+mm}` : mm}/${yyyy}`;
  /* eslint-enable */
}

const timeZonesNames = [
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Addis_Ababa',
  'Africa/Algiers',
  'Africa/Asmara',
  'Africa/Asmera',
  'Africa/Bamako',
  'Africa/Bangui',
  'Africa/Banjul',
  'Africa/Blantyre',
  'Africa/Brazzaville',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Ceuta',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Douala',
  'Africa/El_Aaiun',
  'Africa/Freetown',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Juba',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Kigali',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Lome',
  'Africa/Luanda',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Malabo',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Africa/Mogadishu',
  'Africa/Monrovia',
  'Africa/Nairobi',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Porto-Novo',
  'Africa/Sao_Tome',
  'Africa/Timbuktu',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek',
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/ComodRivadavia',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Atikokan',
  'America/Atka',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Buenos_Aires',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Catamarca',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Coral_Harbour',
  'America/Cordoba',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Ensenada',
  'America/Fort_Wayne',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Valparaiso',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indianapolis',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Jujuy',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Knox_IN',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lima',
  'America/Los_Angeles',
  'America/Louisville',
  'America/Lower_Princes',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Mendoza',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Montreal',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port_of_Spain',
  'America/Port-au-Prince',
  'America/Porto_Acre',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Rosario',
  'America/Santa_Isabel',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Shiprock',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Virgin',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Macquarie',
  'Antarctica/Mawson',
  'Antarctica/McMurdo',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/South_Pole',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'Arctic/Longyearbyen',
  'Asia/Aden',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Anadyr',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Ashkhabad',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Calcutta',
  'Asia/Choibalsan',
  'Asia/Chongqing',
  'Asia/Chungking',
  'Asia/Colombo',
  'Asia/Dacca',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Gaza',
  'Asia/Harbin',
  'Asia/Hebron',
  'Asia/Ho_Chi_Minh',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Irkutsk',
  'Asia/Istanbul',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Kashgar',
  'Asia/Kathmandu',
  'Asia/Katmandu',
  'Asia/Khandyga',
  'Asia/Kolkata',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Kuwait',
  'Asia/Macao',
  'Asia/Macau',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Oral',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qyzylorda',
  'Asia/Rangoon',
  'Asia/Riyadh',
  'Asia/Saigon',
  'Asia/Sakhalin',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Tel_Aviv',
  'Asia/Thimbu',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Ujung_Pandang',
  'Asia/Ulaanbaatar',
  'Asia/Ulan_Bator',
  'Asia/Urumqi',
  'Asia/Ust-Nera',
  'Asia/Vientiane',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Atlantic/Azores',
  'Atlantic/Bermuda',
  'Atlantic/Canary',
  'Atlantic/Cape_Verde',
  'Atlantic/Faeroe',
  'Atlantic/Faroe',
  'Atlantic/Jan_Mayen',
  'Atlantic/Madeira',
  'Atlantic/Reykjavik',
  'Atlantic/South_Georgia',
  'Atlantic/St_Helena',
  'Atlantic/Stanley',
  'Australia/ACT',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Canberra',
  'Australia/Currie',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/LHI',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/North',
  'Australia/NSW',
  'Australia/Perth',
  'Australia/Queensland',
  'Australia/South',
  'Australia/Sydney',
  'Australia/Tasmania',
  'Australia/Victoria',
  'Australia/West',
  'Australia/Yancowinna',
  'Brazil/Acre',
  'Brazil/DeNoronha',
  'Brazil/East',
  'Brazil/West',
  'Canada/Atlantic',
  'Canada/Central',
  'Canada/Eastern',
  'Canada/East-Saskatchewan',
  'Canada/Mountain',
  'Canada/Newfoundland',
  'Canada/Pacific',
  'Canada/Saskatchewan',
  'Canada/Yukon',
  'Chile/Continental',
  'Chile/EasterIsland',
  'Cuba',
  'Egypt',
  'Eire',
  'Etc/GMT',
  'Etc/GMT+0',
  'Etc/UCT',
  'Etc/Universal',
  'Etc/UTC',
  'Etc/Zulu',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Athens',
  'Europe/Belfast',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Busingen',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Gibraltar',
  'Europe/Guernsey',
  'Europe/Helsinki',
  'Europe/Isle_of_Man',
  'Europe/Istanbul',
  'Europe/Jersey',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Lisbon',
  'Europe/Ljubljana',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Mariehamn',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Nicosia',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara',
  'Europe/San_Marino',
  'Europe/Sarajevo',
  'Europe/Simferopol',
  'Europe/Skopje',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Tiraspol',
  'Europe/Uzhgorod',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zaporozhye',
  'Europe/Zurich',
  'GB',
  'GB-Eire',
  'GMT',
  'GMT+0',
  'GMT0',
  'GMT-0',
  'Greenwich',
  'Hongkong',
  'Iceland',
  'Indian/Antananarivo',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Comoro',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Mayotte',
  'Indian/Reunion',
  'Iran',
  'Israel',
  'Jamaica',
  'Japan',
  'Kwajalein',
  'Libya',
  'Mexico/BajaNorte',
  'Mexico/BajaSur',
  'Mexico/General',
  'Navajo',
  'NZ',
  'NZ-CHAT',
  'Pacific/Apia',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'Pacific/Chuuk',
  'Pacific/Easter',
  'Pacific/Efate',
  'Pacific/Enderbury',
  'Pacific/Fakaofo',
  'Pacific/Fiji',
  'Pacific/Funafuti',
  'Pacific/Galapagos',
  'Pacific/Gambier',
  'Pacific/Guadalcanal',
  'Pacific/Guam',
  'Pacific/Honolulu',
  'Pacific/Johnston',
  'Pacific/Kiritimati',
  'Pacific/Kosrae',
  'Pacific/Kwajalein',
  'Pacific/Majuro',
  'Pacific/Marquesas',
  'Pacific/Midway',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Norfolk',
  'Pacific/Noumea',
  'Pacific/Pago_Pago',
  'Pacific/Palau',
  'Pacific/Pitcairn',
  'Pacific/Pohnpei',
  'Pacific/Ponape',
  'Pacific/Port_Moresby',
  'Pacific/Rarotonga',
  'Pacific/Saipan',
  'Pacific/Samoa',
  'Pacific/Tahiti',
  'Pacific/Tarawa',
  'Pacific/Tongatapu',
  'Pacific/Truk',
  'Pacific/Wake',
  'Pacific/Wallis',
  'Pacific/Yap',
  'Poland',
  'Portugal',
  'PRC',
  'ROC',
  'ROK',
  'Singapore',
  'Turkey',
  'UCT',
  'Universal',
  'US/Alaska',
  'US/Aleutian',
  'US/Arizona',
  'US/Central',
  'US/Eastern',
  'US/East-Indiana',
  'US/Hawaii',
  'US/Indiana-Starke',
  'US/Michigan',
  'US/Mountain',
  'US/Pacific',
  'US/Samoa',
  'UTC',
  'W-SU',
  'Zulu',
];
// const timezones = [
//   {
//     altLabel: 'Dateline Standard Time',
//     abbr: 'DST',
//     value: -720,
//     isdst: false,
//     label: '(UTC-12:00) International Date Line West',
//     utc: ['Etc/GMT+12'],
//   },
//   {
//     altLabel: 'UTC-11',
//     abbr: 'U',
//     value: -660,
//     isdst: false,
//     label: '(UTC-11:00) Coordinated Universal Time-11',
//     utc: ['Etc/GMT+11', 'Pacific/Midway', 'Pacific/Niue', 'Pacific/Pago_Pago'],
//   },
//   {
//     altLabel: 'Hawaiian Standard Time',
//     abbr: 'HST',
//     value: -600,
//     isdst: false,
//     label: '(UTC-10:00) Hawaii',
//     utc: [
//       'Etc/GMT+10',
//       'Pacific/Honolulu',
//       'Pacific/Johnston',
//       'Pacific/Rarotonga',
//       'Pacific/Tahiti',
//     ],
//   },
//   {
//     altLabel: 'Pacific Standard Time',
//     abbr: 'PST',
//     value: -480,
//     isdst: false,
//     label: '(UTC-08:00) Pacific Time (US & Canada)',
//     utc: [
//       'America/Dawson',
//       'America/Los_Angeles',
//       'America/Tijuana',
//       'America/Vancouver',
//       'America/Whitehorse',
//       'PST8PDT',
//     ],
//   },
//   {
//     altLabel: 'US Mountain Standard Time',
//     abbr: 'UMST',
//     value: -420,
//     isdst: false,
//     label: '(UTC-07:00) Arizona',
//     utc: [
//       'America/Creston',
//       'America/Dawson_Creek',
//       'America/Hermosillo',
//       'America/Phoenix',
//       'Etc/GMT+7',
//     ],
//   },
//   {
//     altLabel: 'Central America Standard Time',
//     abbr: 'CAST',
//     value: -360,
//     isdst: false,
//     label: '(UTC-06:00) Central America',
//     utc: [
//       'America/Belize',
//       'America/Costa_Rica',
//       'America/El_Salvador',
//       'America/Guatemala',
//       'America/Managua',
//       'America/Tegucigalpa',
//       'Etc/GMT+6',
//       'Pacific/Galapagos',
//     ],
//   },
//   {
//     altLabel: 'Venezuela Standard Time',
//     abbr: 'VST',
//     value: -270,
//     isdst: false,
//     label: '(UTC-04:30) Caracas',
//     utc: ['America/Caracas'],
//   },
//   {
//     altLabel: 'Paraguay Standard Time',
//     abbr: 'PYT',
//     value: -240,
//     isdst: false,
//     label: '(UTC-04:00) Asuncion',
//     utc: ['America/Asuncion'],
//   },
//   {
//     altLabel: 'Central Brazilian Standard Time',
//     abbr: 'CBST',
//     value: -240,
//     isdst: false,
//     label: '(UTC-04:00) Cuiaba',
//     utc: ['America/Campo_Grande', 'America/Cuiaba'],
//   },
//   {
//     altLabel: 'SA Western Standard Time',
//     abbr: 'SWST',
//     value: -240,
//     isdst: false,
//     label: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
//     utc: [
//       'America/Anguilla',
//       'America/Antigua',
//       'America/Aruba',
//       'America/Barbados',
//       'America/Blanc-Sablon',
//       'America/Boa_Vista',
//       'America/Curacao',
//       'America/Dominica',
//       'America/Grand_Turk',
//       'America/Grenada',
//       'America/Guadeloupe',
//       'America/Guyana',
//       'America/Kralendijk',
//       'America/La_Paz',
//       'America/Lower_Princes',
//       'America/Manaus',
//       'America/Marigot',
//       'America/Martinique',
//       'America/Montserrat',
//       'America/Port_of_Spain',
//       'America/Porto_Velho',
//       'America/Puerto_Rico',
//       'America/Santo_Domingo',
//       'America/St_Barthelemy',
//       'America/St_Kitts',
//       'America/St_Lucia',
//       'America/St_Thomas',
//       'America/St_Vincent',
//       'America/Tortola',
//       'Etc/GMT+4',
//     ],
//   },
//   {
//     altLabel: 'Pacific SA Standard Time',
//     abbr: 'PSST',
//     value: -240,
//     isdst: false,
//     label: '(UTC-04:00) Santiago',
//     utc: ['America/Santiago', 'Antarctica/Palmer'],
//   },
//   {
//     altLabel: 'E. South America Standard Time',
//     abbr: 'ESAST',
//     value: -180,
//     isdst: false,
//     label: '(UTC-03:00) Brasilia',
//     utc: ['America/Sao_Paulo'],
//   },
//   {
//     altLabel: 'Argentina Standard Time',
//     abbr: 'AST',
//     value: -180,
//     isdst: false,
//     label: '(UTC-03:00) Buenos Aires',
//     utc: [
//       'America/Argentina/La_Rioja',
//       'America/Argentina/Rio_Gallegos',
//       'America/Argentina/Salta',
//       'America/Argentina/San_Juan',
//       'America/Argentina/San_Luis',
//       'America/Argentina/Tucuman',
//       'America/Argentina/Ushuaia',
//       'America/Buenos_Aires',
//       'America/Catamarca',
//       'America/Cordoba',
//       'America/Jujuy',
//       'America/Mendoza',
//     ],
//   },
//   {
//     altLabel: 'SA Eastern Standard Time',
//     abbr: 'SEST',
//     value: -180,
//     isdst: false,
//     label: '(UTC-03:00) Cayenne, Fortaleza',
//     utc: [
//       'America/Araguaina',
//       'America/Belem',
//       'America/Cayenne',
//       'America/Fortaleza',
//       'America/Maceio',
//       'America/Paramaribo',
//       'America/Recife',
//       'America/Santarem',
//       'Antarctica/Rothera',
//       'Atlantic/Stanley',
//       'Etc/GMT+3',
//     ],
//   },
//   {
//     altLabel: 'Montevideo Standard Time',
//     abbr: 'MST',
//     value: -180,
//     isdst: false,
//     label: '(UTC-03:00) Montevideo',
//     utc: ['America/Montevideo'],
//   },
//   {
//     altLabel: 'Bahia Standard Time',
//     abbr: 'BST',
//     value: -180,
//     isdst: false,
//     label: '(UTC-03:00) Salvador',
//     utc: ['America/Bahia'],
//   },
//   {
//     altLabel: 'UTC-02',
//     abbr: 'U',
//     value: -120,
//     isdst: false,
//     label: '(UTC-02:00) Coordinated Universal Time-02',
//     utc: ['America/Noronha', 'Atlantic/South_Georgia', 'Etc/GMT+2'],
//   },
//   {
//     altLabel: 'Cape Verde Standard Time',
//     abbr: 'CVST',
//     value: -60,
//     isdst: false,
//     label: '(UTC-01:00) Cape Verde Is.',
//     utc: ['Atlantic/Cape_Verde', 'Etc/GMT+1'],
//   },
//   {
//     altLabel: 'UTC',
//     abbr: 'UTC',
//     value: 0,
//     isdst: false,
//     label: '(UTC) Coordinated Universal Time',
//     utc: ['America/Danmarkshavn', 'Etc/GMT'],
//   },
//   {
//     altLabel: 'GMT Standard Time',
//     abbr: 'GMT',
//     value: 0,
//     isdst: false,
//     label: '(UTC) Edinburgh, London',
//     utc: ['Europe/Isle_of_Man', 'Europe/Guernsey', 'Europe/Jersey', 'Europe/London'],
//   },
//   {
//     altLabel: 'Greenwich Standard Time',
//     abbr: 'GST',
//     value: 0,
//     isdst: false,
//     label: '(UTC) Monrovia, Reykjavik',
//     utc: [
//       'Africa/Abidjan',
//       'Africa/Accra',
//       'Africa/Bamako',
//       'Africa/Banjul',
//       'Africa/Bissau',
//       'Africa/Conakry',
//       'Africa/Dakar',
//       'Africa/Freetown',
//       'Africa/Lome',
//       'Africa/Monrovia',
//       'Africa/Nouakchott',
//       'Africa/Ouagadougou',
//       'Africa/Sao_Tome',
//       'Atlantic/Reykjavik',
//       'Atlantic/St_Helena',
//     ],
//   },
//   {
//     altLabel: 'W. Central Africa Standard Time',
//     abbr: 'WCAST',
//     value: 60,
//     isdst: false,
//     label: '(UTC+01:00) West Central Africa',
//     utc: [
//       'Africa/Algiers',
//       'Africa/Bangui',
//       'Africa/Brazzaville',
//       'Africa/Douala',
//       'Africa/Kinshasa',
//       'Africa/Lagos',
//       'Africa/Libreville',
//       'Africa/Luanda',
//       'Africa/Malabo',
//       'Africa/Ndjamena',
//       'Africa/Niamey',
//       'Africa/Porto-Novo',
//       'Africa/Tunis',
//       'Etc/GMT-1',
//     ],
//   },
//   {
//     altLabel: 'Namibia Standard Time',
//     abbr: 'NST',
//     value: 60,
//     isdst: false,
//     label: '(UTC+01:00) Windhoek',
//     utc: ['Africa/Windhoek'],
//   },
//   {
//     altLabel: 'Egypt Standard Time',
//     abbr: 'EST',
//     value: 120,
//     isdst: false,
//     label: '(UTC+02:00) Cairo',
//     utc: ['Africa/Cairo'],
//   },
//   {
//     altLabel: 'South Africa Standard Time',
//     abbr: 'SAST',
//     value: 120,
//     isdst: false,
//     label: '(UTC+02:00) Harare, Pretoria',
//     utc: [
//       'Africa/Blantyre',
//       'Africa/Bujumbura',
//       'Africa/Gaborone',
//       'Africa/Harare',
//       'Africa/Johannesburg',
//       'Africa/Kigali',
//       'Africa/Lubumbashi',
//       'Africa/Lusaka',
//       'Africa/Maputo',
//       'Africa/Maseru',
//       'Africa/Mbabane',
//       'Etc/GMT-2',
//     ],
//   },
//   {
//     altLabel: 'Turkey Standard Time',
//     abbr: 'TDT',
//     value: 180,
//     isdst: false,
//     label: '(UTC+03:00) Istanbul',
//     utc: ['Europe/Istanbul'],
//   },
//   {
//     altLabel: 'Libya Standard Time',
//     abbr: 'LST',
//     value: 120,
//     isdst: false,
//     label: '(UTC+02:00) Tripoli',
//     utc: ['Africa/Tripoli'],
//   },
//   {
//     altLabel: 'Jordan Standard Time',
//     abbr: 'JST',
//     value: 180,
//     isdst: false,
//     label: '(UTC+03:00) Amman',
//     utc: ['Asia/Amman'],
//   },
//   {
//     altLabel: 'Arabic Standard Time',
//     abbr: 'AST',
//     value: 180,
//     isdst: false,
//     label: '(UTC+03:00) Baghdad',
//     utc: ['Asia/Baghdad'],
//   },
//   {
//     altLabel: 'Kaliningrad Standard Time',
//     abbr: 'KST',
//     value: 180,
//     isdst: false,
//     label: '(UTC+03:00) Kaliningrad, Minsk',
//     utc: ['Europe/Kaliningrad', 'Europe/Minsk'],
//   },
//   {
//     altLabel: 'Arab Standard Time',
//     abbr: 'AST',
//     value: 180,
//     isdst: false,
//     label: '(UTC+03:00) Kuwait, Riyadh',
//     utc: ['Asia/Aden', 'Asia/Bahrain', 'Asia/Kuwait', 'Asia/Qatar', 'Asia/Riyadh'],
//   },
//   {
//     altLabel: 'E. Africa Standard Time',
//     abbr: 'EAST',
//     value: 180,
//     isdst: false,
//     label: '(UTC+03:00) Nairobi',
//     utc: [
//       'Africa/Addis_Ababa',
//       'Africa/Asmera',
//       'Africa/Dar_es_Salaam',
//       'Africa/Djibouti',
//       'Africa/Juba',
//       'Africa/Kampala',
//       'Africa/Khartoum',
//       'Africa/Mogadishu',
//       'Africa/Nairobi',
//       'Antarctica/Syowa',
//       'Etc/GMT-3',
//       'Indian/Antananarivo',
//       'Indian/Comoro',
//       'Indian/Mayotte',
//     ],
//   },
//   {
//     altLabel: 'Moscow Standard Time',
//     abbr: 'MSK',
//     value: 180,
//     isdst: false,
//     label: '(UTC+03:00) Moscow, St. Petersburg, Volgograd',
//     utc: ['Europe/Kirov', 'Europe/Moscow', 'Europe/Simferopol', 'Europe/Volgograd'],
//   },
//   {
//     altLabel: 'Samara Time',
//     abbr: 'SAMT',
//     value: 240,
//     isdst: false,
//     label: '(UTC+04:00) Samara, Ulyanovsk, Saratov',
//     utc: ['Europe/Astrakhan', 'Europe/Samara', 'Europe/Ulyanovsk'],
//   },
//   {
//     altLabel: 'Arabian Standard Time',
//     abbr: 'AST',
//     value: 240,
//     isdst: false,
//     label: '(UTC+04:00) Abu Dhabi, Dubai, Muscat',
//     utc: ['Asia/Dubai', 'Asia/Muscat', 'Etc/GMT-4'],
//   },
//   {
//     altLabel: 'Mauritius Standard Time',
//     abbr: 'MST',
//     value: 240,
//     isdst: false,
//     label: '(UTC+04:00) Port Louis',
//     utc: ['Indian/Mahe', 'Indian/Mauritius', 'Indian/Reunion'],
//   },
//   {
//     altLabel: 'Georgian Standard Time',
//     abbr: 'GET',
//     value: 240,
//     isdst: false,
//     label: '(UTC+04:00) Tbilisi',
//     utc: ['Asia/Tbilisi'],
//   },
//   {
//     altLabel: 'Caucasus Standard Time',
//     abbr: 'CST',
//     value: 240,
//     isdst: false,
//     label: '(UTC+04:00) Yerevan',
//     utc: ['Asia/Yerevan'],
//   },
//   {
//     altLabel: 'Afghanistan Standard Time',
//     abbr: 'AST',
//     value: 270,
//     isdst: false,
//     label: '(UTC+04:30) Kabul',
//     utc: ['Asia/Kabul'],
//   },
//   {
//     altLabel: 'West Asia Standard Time',
//     abbr: 'WAST',
//     value: 300,
//     isdst: false,
//     label: '(UTC+05:00) Ashgabat, Tashkent',
//     utc: [
//       'Antarctica/Mawson',
//       'Asia/Aqtau',
//       'Asia/Aqtobe',
//       'Asia/Ashgabat',
//       'Asia/Dushanbe',
//       'Asia/Oral',
//       'Asia/Samarkand',
//       'Asia/Tashkent',
//       'Etc/GMT-5',
//       'Indian/Kerguelen',
//       'Indian/Maldives',
//     ],
//   },
//   {
//     altLabel: 'Yekaterinburg Time',
//     abbr: 'YEKT',
//     value: 300,
//     isdst: false,
//     label: '(UTC+05:00) Yekaterinburg',
//     utc: ['Asia/Yekaterinburg'],
//   },
//   {
//     altLabel: 'Pakistan Standard Time',
//     abbr: 'PKT',
//     value: 300,
//     isdst: false,
//     label: '(UTC+05:00) Islamabad, Karachi',
//     utc: ['Asia/Karachi'],
//   },
//   {
//     altLabel: 'India Standard Time',
//     abbr: 'IST',
//     value: 330,
//     isdst: false,
//     label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
//     utc: ['Asia/Kolkata'],
//   },
//   {
//     altLabel: 'Sri Lanka Standard Time',
//     abbr: 'SLST',
//     value: 330,
//     isdst: false,
//     label: '(UTC+05:30) Sri Jayawardenepura',
//     utc: ['Asia/Colombo'],
//   },
//   {
//     altLabel: 'Nepal Standard Time',
//     abbr: 'NST',
//     value: 345,
//     isdst: false,
//     label: '(UTC+05:45) Kathmandu',
//     utc: ['Asia/Kathmandu'],
//   },
//   {
//     altLabel: 'Central Asia Standard Time',
//     abbr: 'CAST',
//     value: 360,
//     isdst: false,
//     label: '(UTC+06:00) Astana',
//     utc: [
//       'Antarctica/Vostok',
//       'Asia/Almaty',
//       'Asia/Bishkek',
//       'Asia/Qyzylorda',
//       'Asia/Urumqi',
//       'Etc/GMT-6',
//       'Indian/Chagos',
//     ],
//   },
//   {
//     altLabel: 'Bangladesh Standard Time',
//     abbr: 'BST',
//     value: 360,
//     isdst: false,
//     label: '(UTC+06:00) Dhaka',
//     utc: ['Asia/Dhaka', 'Asia/Thimphu'],
//   },
//   {
//     altLabel: 'Myanmar Standard Time',
//     abbr: 'MST',
//     value: 390,
//     isdst: false,
//     label: '(UTC+06:30) Yangon (Rangoon)',
//     utc: ['Asia/Rangoon', 'Indian/Cocos'],
//   },
//   {
//     altLabel: 'SE Asia Standard Time',
//     abbr: 'SAST',
//     value: 420,
//     isdst: false,
//     label: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
//     utc: [
//       'Antarctica/Davis',
//       'Asia/Bangkok',
//       'Asia/Hovd',
//       'Asia/Jakarta',
//       'Asia/Phnom_Penh',
//       'Asia/Pontianak',
//       'Asia/Saigon',
//       'Asia/Vientiane',
//       'Etc/GMT-7',
//       'Indian/Christmas',
//     ],
//   },
//   {
//     altLabel: 'N. Central Asia Standard Time',
//     abbr: 'NCAST',
//     value: 420,
//     isdst: false,
//     label: '(UTC+07:00) Novosibirsk',
//     utc: ['Asia/Novokuznetsk', 'Asia/Novosibirsk', 'Asia/Omsk'],
//   },
//   {
//     altLabel: 'China Standard Time',
//     abbr: 'CST',
//     value: 480,
//     isdst: false,
//     label: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
//     utc: ['Asia/Hong_Kong', 'Asia/Macau', 'Asia/Shanghai'],
//   },
//   {
//     altLabel: 'North Asia Standard Time',
//     abbr: 'NAST',
//     value: 480,
//     isdst: false,
//     label: '(UTC+08:00) Krasnoyarsk',
//     utc: ['Asia/Krasnoyarsk'],
//   },
//   {
//     altLabel: 'Singapore Standard Time',
//     abbr: 'MPST',
//     value: 480,
//     isdst: false,
//     label: '(UTC+08:00) Kuala Lumpur, Singapore',
//     utc: [
//       'Asia/Brunei',
//       'Asia/Kuala_Lumpur',
//       'Asia/Kuching',
//       'Asia/Makassar',
//       'Asia/Manila',
//       'Asia/Singapore',
//       'Etc/GMT-8',
//     ],
//   },
//   {
//     altLabel: 'W. Australia Standard Time',
//     abbr: 'WAST',
//     value: 480,
//     isdst: false,
//     label: '(UTC+08:00) Perth',
//     utc: ['Antarctica/Casey', 'Australia/Perth'],
//   },
//   {
//     altLabel: 'Taipei Standard Time',
//     abbr: 'TST',
//     value: 480,
//     isdst: false,
//     label: '(UTC+08:00) Taipei',
//     utc: ['Asia/Taipei'],
//   },
//   {
//     altLabel: 'Ulaanbaatar Standard Time',
//     abbr: 'UST',
//     value: 480,
//     isdst: false,
//     label: '(UTC+08:00) Ulaanbaatar',
//     utc: ['Asia/Choibalsan', 'Asia/Ulaanbaatar'],
//   },
//   {
//     altLabel: 'North Asia East Standard Time',
//     abbr: 'NAEST',
//     value: 480,
//     isdst: false,
//     label: '(UTC+08:00) Irkutsk',
//     utc: ['Asia/Irkutsk'],
//   },
//   {
//     altLabel: 'Japan Standard Time',
//     abbr: 'JST',
//     value: 540,
//     isdst: false,
//     label: '(UTC+09:00) Osaka, Sapporo, Tokyo',
//     utc: ['Asia/Dili', 'Asia/Jayapura', 'Asia/Tokyo', 'Etc/GMT-9', 'Pacific/Palau'],
//   },
//   {
//     altLabel: 'Korea Standard Time',
//     abbr: 'KST',
//     value: 540,
//     isdst: false,
//     label: '(UTC+09:00) Seoul',
//     utc: ['Asia/Pyongyang', 'Asia/Seoul'],
//   },
//   {
//     altLabel: 'Cen. Australia Standard Time',
//     abbr: 'CAST',
//     value: 570,
//     isdst: false,
//     label: '(UTC+09:30) Adelaide',
//     utc: ['Australia/Adelaide', 'Australia/Broken_Hill'],
//   },
//   {
//     altLabel: 'AUS Central Standard Time',
//     abbr: 'ACST',
//     value: 570,
//     isdst: false,
//     label: '(UTC+09:30) Darwin',
//     utc: ['Australia/Darwin'],
//   },
//   {
//     altLabel: 'E. Australia Standard Time',
//     abbr: 'EAST',
//     value: 600,
//     isdst: false,
//     label: '(UTC+10:00) Brisbane',
//     utc: ['Australia/Brisbane', 'Australia/Lindeman'],
//   },
//   {
//     altLabel: 'AUS Eastern Standard Time',
//     abbr: 'AEST',
//     value: 600,
//     isdst: false,
//     label: '(UTC+10:00) Canberra, Melbourne, Sydney',
//     utc: ['Australia/Melbourne', 'Australia/Sydney'],
//   },
//   {
//     altLabel: 'West Pacific Standard Time',
//     abbr: 'WPST',
//     value: 600,
//     isdst: false,
//     label: '(UTC+10:00) Guam, Port Moresby',
//     utc: [
//       'Antarctica/DumontDUrville',
//       'Etc/GMT-10',
//       'Pacific/Guam',
//       'Pacific/Port_Moresby',
//       'Pacific/Saipan',
//       'Pacific/Truk',
//     ],
//   },
//   {
//     altLabel: 'Tasmania Standard Time',
//     abbr: 'TST',
//     value: 600,
//     isdst: false,
//     label: '(UTC+10:00) Hobart',
//     utc: ['Australia/Currie', 'Australia/Hobart'],
//   },
//   {
//     altLabel: 'Yakutsk Standard Time',
//     abbr: 'YST',
//     value: 540,
//     isdst: false,
//     label: '(UTC+09:00) Yakutsk',
//     utc: ['Asia/Chita', 'Asia/Khandyga', 'Asia/Yakutsk'],
//   },
//   {
//     altLabel: 'Central Pacific Standard Time',
//     abbr: 'CPST',
//     value: 660,
//     isdst: false,
//     label: '(UTC+11:00) Solomon Is., New Caledonia',
//     utc: [
//       'Antarctica/Macquarie',
//       'Etc/GMT-11',
//       'Pacific/Efate',
//       'Pacific/Guadalcanal',
//       'Pacific/Kosrae',
//       'Pacific/Noumea',
//       'Pacific/Ponape',
//     ],
//   },
//   {
//     altLabel: 'Vladivostok Standard Time',
//     abbr: 'VST',
//     value: 660,
//     isdst: false,
//     label: '(UTC+11:00) Vladivostok',
//     utc: ['Asia/Sakhalin', 'Asia/Ust-Nera', 'Asia/Vladivostok'],
//   },
//   {
//     altLabel: 'New Zealand Standard Time',
//     abbr: 'NZST',
//     value: 720,
//     isdst: false,
//     label: '(UTC+12:00) Auckland, Wellington',
//     utc: ['Antarctica/McMurdo', 'Pacific/Auckland'],
//   },
//   {
//     altLabel: 'UTC+12',
//     abbr: 'U',
//     value: 720,
//     isdst: false,
//     label: '(UTC+12:00) Coordinated Universal Time+12',
//     utc: [
//       'Etc/GMT-12',
//       'Pacific/Funafuti',
//       'Pacific/Kwajalein',
//       'Pacific/Majuro',
//       'Pacific/Nauru',
//       'Pacific/Tarawa',
//       'Pacific/Wake',
//       'Pacific/Wallis',
//     ],
//   },
//   {
//     altLabel: 'Fiji Standard Time',
//     abbr: 'FST',
//     value: 720,
//     isdst: false,
//     label: '(UTC+12:00) Fiji',
//     utc: ['Pacific/Fiji'],
//   },
//   {
//     altLabel: 'Magadan Standard Time',
//     abbr: 'MST',
//     value: 720,
//     isdst: false,
//     label: '(UTC+12:00) Magadan',
//     utc: ['Asia/Anadyr', 'Asia/Kamchatka', 'Asia/Magadan', 'Asia/Srednekolymsk'],
//   },
//   {
//     altLabel: 'Tonga Standard Time',
//     abbr: 'TST',
//     value: 780,
//     isdst: false,
//     label: "(UTC+13:00) Nuku'alofa",
//     utc: ['Etc/GMT-13', 'Pacific/Enderbury', 'Pacific/Fakaofo', 'Pacific/Tongatapu'],
//   },
//   {
//     altLabel: 'Samoa Standard Time',
//     abbr: 'SST',
//     value: 780,
//     isdst: false,
//     label: '(UTC+13:00) Samoa',
//     utc: ['Pacific/Apia'],
//   },
// ];

export default timeZonesNames;

// Deprecated
// export function convertSiteToUtc(minutes: number, siteZone: number): string {
//   let convertMins = minutes - siteZone;
//   if (Math.sign(convertMins) === -1) {
//     convertMins = 1440 - Math.abs(convertMins);
//   }

//   if (convertMins > 1440) {
//     convertMins -= 1440;
//   }
//   const hour = Math.floor(convertMins / 60) === 24 ? 0 : Math.floor(convertMins / 60);
//   const mins = convertMins % 60;
//   return `${hour < 10 ? `0${hour}` : hour}:${mins < 10 ? `0${mins}` : mins}`;
// }

// export function convertUtcToSite(minutes: number, siteZone: number): string {
//   let convertMins = minutes + siteZone;
//   if (Math.sign(convertMins) === -1) {
//     convertMins = 1440 - Math.abs(convertMins);
//   }

//   if (convertMins >= 1440) {
//     convertMins -= 1440;
//   }

//   const hour = Math.floor(convertMins / 60) === 24 ? 0 : Math.floor(convertMins / 60);
//   const mins = convertMins % 60;
//   return `${hour < 10 ? `0${hour}` : hour}:${mins < 10 ? `0${mins}` : mins}`;
// }

// export const getZoneNamewithOffset = (offset: number): string => {
//   const zone = timezones.find((t) => t.value === offset);
//   if (zone) {
//     return zone.utc[0];
//   }
//   return '';
// };

export const convertSiteToUtcToday = (date?: any): string => {
  if (date) {
    return moment(date)
      .startOf('day')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss');
  }
  return moment()
    .startOf('day')
    .utc()
    .format('YYYY-MM-DDTHH:mm:ss');
};

export const convertUtcTodayToSite = (date: string): any => {
  return moment(date);
};

export const createDateWithDateFormatter = (date: any, format = 'DD.MM.YYYY'): string => {
  return moment(date, format)
    .startOf('day')
    .utc()
    .format('YYYY-MM-DDTHH:mm:ss');
};

export const createDateWithNoTimestamp = (date: any, format = 'DD.MM.YYYY'): string => {
  return moment(date, format)
    .startOf('day')
    .utc()
    .format('YYYY-MM-DD');
};

export const convertDate = (date: string): string =>
  (dateFormater(date) as string)
    ?.split('/')
    .reverse()
    .join('-');

export const currentWeekStartDate = (): string => {
  return moment()
    .startOf('week')
    .format('YYYY-MM-DD');
};

export const currentWeekEndDate = (): string => {
  return moment()
    .endOf('week')
    .format('YYYY-MM-DD');
};

export const getDecimals = (num: number, base = 2): string => {
  const [number, decimals] = `${num}`.split('.');
  return `${number}.${decimals.slice(0, base)}`;
};

export const formatDecimals = (num: number): number | string => {
  if (!num) {
    return 'N/A';
  }
  return Number.isInteger(num) ? num : getDecimals(num);
};

export const convertSecondsToHours = (milliSecs: number): number | string => {
  if (!milliSecs) {
    return 0;
  }
  const hours = milliSecs / 3600;
  return formatDecimals(hours);
};

/**
 * Date Local TimeZone
 */

export const showDateInYearFormat = (str: string): string =>
  moment(str)
    .utc()
    .format('YYYY-MM-DD');

export const convertSecsToCounter = (secs: number): string => {
  const seconds = Math.floor((secs % (1000 * 60)) / 1000);
  const minutes = Math.floor((secs % (1000 * 60 * 60)) / (1000 * 60));
  const hours = Math.floor((secs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
    seconds < 10 ? `0${seconds}` : seconds
  }`;
};

export const createDateWithDateTimeFormatter = (date: any): string => {
  return moment(date)
    .utc()
    .format('YYYY-MM-DDTHH:mm:ss');
};

export const reverseDateString = (date = ''): string =>
  date
    .split('-')
    .reverse()
    .join('-');

export const convertMinutesToHours = (mins: number): string => {
  const minutes = Math.floor(mins % 60);
  const hours = Math.floor(mins / 60);
  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
};

export const diffBetweenTwoTimes = (time1: any, time2: any): any => {
  return Math.sign(time1.diff(time2, 'minutes'));
};
