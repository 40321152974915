import { AxiosResponse } from 'axios';
import axios, { CancelToken } from 'api';
import urls from 'api/urls';
import { Site, Network } from 'store/state/sites';
import { CreateSiteData } from 'store/actions/sites';
import { USER_CANCELLED } from 'utils/constants';

import { CreateSubContractorData, InventoryMeta, powerBiEditRequestType } from './types';

let siteApiSource = CancelToken.source();

export const getSiteApiSource = (): any => siteApiSource;

export const cancelListSitesApi = (): void => {
  siteApiSource.cancel(USER_CANCELLED);
  siteApiSource = CancelToken.source();
};

export async function listSitesApi(
  clientId: string,
  noCache = false,
): Promise<AxiosResponse<Site[]>> {
  if (!noCache) {
    // cancelListSitesApi();
  }
  const response = await axios.get(urls.sites.list(clientId), {
    cancelToken: siteApiSource.token,
    urlParams: { clientId },
  });
  return response.data;
}

export async function createSiteApi(
  data: CreateSiteData,
  clientId: string,
): Promise<AxiosResponse<Site>> {
  const response = await axios.post(urls.sites.create, { ...data, client_id: clientId });
  return response.data;
}

export async function listNetworksApi(): Promise<AxiosResponse<Network[]>> {
  const response = await axios.get(urls.sites.listNetworks);
  return response.data;
}

export async function configureSiteNetworkApi(
  site_id: string,
  network_id: string,
): Promise<AxiosResponse<void>> {
  const response = await axios.patch(urls.sites.assignNetwork(network_id), { site_id });
  return response.data;
}

export const createSubContractor = async (
  data: CreateSubContractorData,
  siteId: string,
): Promise<AxiosResponse<any>> => {
  const response = await axios.post(urls.sites.createSubContractor(siteId), { ...data });
  return response.data;
};

export const listWorkerRolesApi = async (siteId: string): Promise<AxiosResponse<any>> => {
  const response = await axios.get(urls.sites.rolesList(siteId), {
    cancelToken: siteApiSource.token,
    params: {
      sort: 'name,asc',
    },
  });
  if (response.data.status === 'success') {
    const { result } = response.data.data;
    return result.map(({ name, id }: any) => ({
      value: id,
      label: name,
    }));
  }
  return Promise.reject(response.data);
};

export const listSubContractorsApi = async (siteId: string): Promise<any> => {
  const response = await axios.get(urls.sites.contractorsList(siteId), {
    cancelToken: siteApiSource.token,
    params: {
      sort: 'name,asc',
    },
  });
  if (response.data.status === 'success') {
    const { result } = response.data.data;
    const contractors = result.map(({ name, id }: any) => ({
      value: id,
      label: name,
    }));
    return contractors;
  }
  return Promise.reject(response.data);
};

export const editSubContractorApi = async (
  siteId: string,
  id: string,
  data: any,
): Promise<AxiosResponse<any>> => {
  const response = await axios.post(urls.sites.editSubContractor(siteId, id), { ...data });
  return response.data;
};

export const listUnApprovedHelmetApi = async (networkId?: string): Promise<any> => {
  if (!networkId) {
    return [];
  }
  const response = await axios.get(urls.assets.listUnApproved(networkId as string), {
    cancelToken: siteApiSource.token,
    params: {
      sort: 'wnt_inventory_id,asc',
      assigned: 0,
    },
  });
  if (response.data.status === 'success') {
    const { data } = response.data;
    return data.map(({ wnt_inventory_id, id }: any) => ({
      value: id,
      label: wnt_inventory_id,
    }));
  }
  return Promise.reject(response.data);
};

export const listPowerBI = async (siteId: string): Promise<AxiosResponse<any>> => {
  const response = await axios.get(urls.sites.listPowerBI(siteId));
  return response.data;
};

/**
 * Maintance Site Api's
 */

let siteMetaSource = CancelToken.source();

export const cancelSiteMeta = (): void => siteMetaSource.cancel(USER_CANCELLED);

export async function selectedSiteMetaCountApi(
  site_id: string,
  time: any = {},
): Promise<AxiosResponse<InventoryMeta>> {
  cancelSiteMeta();
  siteMetaSource = CancelToken.source();
  const header: any = {
    cancelToken: siteApiSource.token,
  };

  if (time.startDate && time.endDate) {
    header.params = {
      startDate: time.startDate,
      endDate: time.endDate,
    };
  }
  const response = await axios.get(urls.sites.inventoryMeta(site_id), header);
  return response.data;
}

export const workerRolesApi = async (
  site_id: string,
  filters: any,
): Promise<AxiosResponse<any>> => {
  const response = await axios.get(urls.sites.rolesList(site_id), {
    cancelToken: siteApiSource.token,
    params: {
      ...filters,
    },
  });
  return response.data;
};

export const subContractorsApi = async (siteId: string, filters: any): Promise<any> => {
  const response = await axios.get(urls.sites.contractorsList(siteId), {
    cancelToken: siteApiSource.token,
    params: {
      ...filters,
    },
  });
  return response.data;
};

export const editWorkerRoleApi = async (
  site_id: string,
  role_id: string,
  body: any,
): Promise<AxiosResponse<any>> => {
  const response = await axios.post(urls.sites.editWorkerRole(site_id, role_id), body);
  return response.data;
};

export const updatePowerBiReport = async (
  siteId: string,
  reportId: string,
  data: powerBiEditRequestType,
): Promise<AxiosResponse<any>> => {
  const response = await axios.post(urls.sites.updatePowerBiReport(siteId, reportId), data);
  return response.data;
};

export const createPowerBIReport = async (
  siteId: string,
  data: powerBiEditRequestType,
): Promise<AxiosResponse<any>> => {
  const response = await axios.post(urls.sites.createPowerBiReport(siteId), data);
  return response.data;
};
