import { produce } from 'immer';
import initialState, { Clients } from 'store/state/clients';
import { ClientsActions } from 'store/actions/clients';
import {
  CLIENT__VIEW_CLIENT,
  CLIENT__LIST_CLIENTS_REQ,
  CLIENT__LIST_CLIENTS_SUCCESS,
  CLIENT__LIST_CLIENTS_FAILED,
  CLIENT__CREATE_CLIENT_SUCCESS,
} from 'store/actions/actionTypes';

const clientReducer = (state = initialState, action: ClientsActions): Clients =>
  produce(state, (draft) => {
    switch (action.type) {
      case CLIENT__LIST_CLIENTS_REQ:
        draft.isLoading = true;
        break;
      case CLIENT__LIST_CLIENTS_SUCCESS:
        draft.isLoading = false;
        draft.data = action.payload;
        break;
      case CLIENT__CREATE_CLIENT_SUCCESS:
        draft.data.unshift(action.payload);
        break;
      case CLIENT__VIEW_CLIENT:
        draft.selected = action.payload;
        break;
      case CLIENT__LIST_CLIENTS_FAILED:
        draft.isLoading = false;
        break;
      default:
        break;
    }
  });

export default clientReducer;
