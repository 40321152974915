import React, { lazy, useEffect, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Router, Route } from 'react-router-dom';
import { message, notification } from 'antd';
import { createBrowserHistory } from 'history';
import moment from 'moment';

import { APP, LOGIN, LOGOUT, REGISTER, FORGOT_PASSWORD, RESET_PASSWORD } from 'utils/routes';
import { getFromLocalStorage, setToLocalStorage } from 'utils/browser-storage';
import { noInternetMessage, internetBackMessage, unknowError } from 'utils/constants';
import { AUTHENTICATE } from 'store/actions/user';

import PublicRoute from 'ui/components/PublicRoute';
import PrivateRoute from 'ui/components/PrivateRoute';
import LoadingPage from 'ui/components/Loading';
import { getCurrentUserApi } from 'api/user';
import { USER__LAST_SELECTION } from 'store/actions/actionTypes';

const history = createBrowserHistory();
function ProtectedRouter(): React.ReactElement {
  const [isUserLoading, setUserLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  async function retriveLoggedInUser(): Promise<void> {
    const token = getFromLocalStorage('auth');
    if (token) {
      const userInfo = getFromLocalStorage('user');
      if (userInfo) {
        const user = JSON.parse(userInfo);
        try {
          // @ts-ignore
          const {
            data: { locale, name, last_site_id, last_client_id },
          } = (await getCurrentUserApi()) as any;
          if (locale !== user.locale) {
            // user.locale = 'en';
          }
          if (name && user.name !== name) {
            user.name = name;
            setToLocalStorage('user', user);
          }
          user.last_client_id = last_client_id;
          user.last_site_id = last_site_id;
          dispatch({
            type: USER__LAST_SELECTION,
            payload: {
              last_client_id,
              last_site_id,
            },
          });
        } catch (error) {
          window.navigator.onLine && notification.error({ message: unknowError(), duration: 1 });
        }

        /** Hot Jar User Attribut Integration */
        // @ts-ignore
        window.hj &&
          // @ts-ignore
          window.hj('identify', user.email, {
            name: user.name,
            locale: user.locale,
          });

        dispatch({ type: AUTHENTICATE, payload: user });
        moment.locale('en');
      }
    }
    setUserLoading(false);
  }

  useEffect(() => {
    retriveLoggedInUser();
  }, []);

  window.onoffline = (): void => {
    message.error({ content: noInternetMessage(), duration: 0 });
  };

  window.ononline = (): void => {
    message.destroy();
    message.success({ content: internetBackMessage(), duration: 1 });
  };

  return (
    <Fragment>
      {isUserLoading ? (
        <LoadingPage />
      ) : (
        <Router history={history}>
          <Switch>
            <PublicRoute path={LOGIN} component={lazy(() => import('ui/pages/common/login'))} />
            <PublicRoute
              path={FORGOT_PASSWORD}
              component={lazy(() => import('ui/pages/common/forgotPassword'))}
            />
            <PublicRoute
              path={RESET_PASSWORD}
              component={lazy(() => import('ui/pages/common/resetPassword'))}
            />
            <Route path={REGISTER} component={lazy(() => import('ui/pages/common/register'))} />
            <Route path={LOGOUT} component={lazy(() => import('ui/pages/common/logout'))} />
            <PrivateRoute path={APP} component={lazy(() => import('ui/pages/common/app'))} />
          </Switch>
        </Router>
      )}
    </Fragment>
  );
}

export default ProtectedRouter;
