import { produce } from 'immer';
import initialState, { Floors, Floor } from 'store/state/floors';
import {
  IN__VIEW_FLOOR_REQ,
  IN__VIEW_FLOOR_SUCCESS,
  IN__VIEW_FLOOR_FAILED,
  IN__LIST_FLOORS_REQ,
  IN__LIST_FLOORS_SUCCESS,
  IN__LIST_FLOORS_FAILED,
  IN__DUPLICATE_FLOOR_REQ,
  IN__DUPLICATE_FLOOR_SUCCESS,
  IN__DUPLICATE_FLOOR_FAILED,
  IN__SET_FLOOR_TO_EDIT,
  IN__SET_CREATE_EDIT_FLOOR_VISIBLE,
  IN__RESET_FLOORS,
  IN__CREATE_FLOOR_SUCCESS,
  IN__EDIT_FLOOR_SUCESS,
  IN__DELETE_FLOOR_SUCCESS,
} from 'store/actions/actionTypes';

const sortFloorsByAltitude = (data: Floor[]): Floor[] => {
  const sorted = [...data].sort((a: Floor, b: Floor) => {
    if (a.altitude > b.altitude) {
      return 1;
    }
    if (a.altitude < b.altitude) {
      return -1;
    }
    if (a.altitude === b.altitude) {
      return a.id > b.id ? 1 : -1;
    }
    return 0;
  });
  return sorted;
};

const floorReducer = (state = initialState, action: any): Floors =>
  produce(state, (draft) => {
    switch (action.type) {
      case IN__CREATE_FLOOR_SUCCESS:
      case IN__DUPLICATE_FLOOR_SUCCESS:
        // @ts-ignore
        draft.data = sortFloorsByAltitude([...draft.data, action.payload]);
        draft.isLoading = false;
        draft.selected = action.payload.id;
        draft.selectedFloor = action.payload;
        break;
      case IN__LIST_FLOORS_REQ:
        draft.isLoading = true;
        draft.data = [];
        draft.selected = null;
        draft.selectedFloor = null;
        draft.isFetched = false;
        break;
      case IN__DUPLICATE_FLOOR_REQ:
      case IN__VIEW_FLOOR_REQ:
        draft.isLoading = true;
        break;
      case IN__EDIT_FLOOR_SUCESS:
        draft.data.forEach((floor: Floor, index: number) => {
          if (`${floor.id}` === `${action.payload.id}`) {
            // @ts-ignore
            draft.data[index] = {
              ...action.payload,
              online_active_worker_count: draft.data[index].online_active_worker_count,
            };
          }
        });
        draft.data = sortFloorsByAltitude(draft.data);
        draft.selectedFloor = draft.data.find(
          (floor: Floor) => `${floor.id}` === `${draft.selected}`,
        ) as any;
        break;
      case IN__LIST_FLOORS_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        draft.isFetched = true;
        break;
      case IN__VIEW_FLOOR_FAILED:
      case IN__DUPLICATE_FLOOR_FAILED:
        draft.isLoading = false;
        break;
      case IN__LIST_FLOORS_FAILED:
        draft.isLoading = false;
        draft.isFetched = true;
        break;
      case IN__VIEW_FLOOR_SUCCESS:
        draft.isLoading = false;
        draft.selected = action.payload;
        draft.selectedFloor = draft.data.find(
          (floor: Floor) => `${floor.id}` === `${action.payload}`,
        ) as any;
        break;
      case IN__RESET_FLOORS:
        draft.isLoading = initialState.isLoading;
        draft.selected = initialState.selected;
        draft.selectedFloor = initialState.selectedFloor;
        draft.data = initialState.data;
        draft.floorToEdit = initialState.floorToEdit;
        draft.isCreateEditVisible = initialState.isCreateEditVisible;
        draft.isFetched = initialState.isFetched;
        break;
      case IN__SET_FLOOR_TO_EDIT:
        draft.floorToEdit = action.payload;
        draft.isCreateEditVisible = true;
        break;
      case IN__SET_CREATE_EDIT_FLOOR_VISIBLE:
        draft.floorToEdit = null;
        draft.isCreateEditVisible = action.payload;
        break;
      case IN__DELETE_FLOOR_SUCCESS:
        draft.data = draft.data.filter((floor: Floor) => `${floor.id}` !== `${action.payload}`);
        if (draft.data.length) {
          draft.selected = (draft.data[0] as any).id;
          // @ts-ignore
          [draft.selectedFloor] = draft.data;
        }
        break;
      default:
        break;
    }
  });

export default floorReducer;
