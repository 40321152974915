import { Coordinate } from 'store/state/floors';

export interface Wall {
  id: string;
  wall_id: string;
  coordinates: Coordinate[][];
  att: number;
  wt: number;
  metadata: {
    feat: Coordinate[][];
    att: number;
    wt: number;
  };
}

export interface Walls {
  data: Wall[];
  selected: string | null;
  isLoading: boolean;
  searchType: string;
  filterResult: Wall[];
}

const initialState: Walls = {
  data: [],
  selected: null,
  isLoading: false,
  searchType: '',
  filterResult: [],
};

export default initialState;
