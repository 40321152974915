import { produce } from 'immer';
import { SET_PRODUCTIVITY_LIST_TOGGLE } from 'store/actions/actionTypes';
import { ToogleProductivityListActionType } from 'store/actions/productivity';
import initialState, { ProductivityStateType } from 'store/state/productivity';

const productivityReducer = (
  state: ProductivityStateType = initialState,
  action: ToogleProductivityListActionType,
): ProductivityStateType =>
  produce(state, (draft: ProductivityStateType) => {
    switch (action.type) {
      case SET_PRODUCTIVITY_LIST_TOGGLE:
        draft.isToggleFetch = !draft.isToggleFetch;
        break;
      default:
        break;
    }
  });

export default productivityReducer;
