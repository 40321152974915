export type MapsStateType = {
  params: any;
  data: {
    loading: boolean;
    workers: {
      loading: boolean;
      fetched: boolean;
      data: any;
    };
    siteId: {
      fetched: boolean;
      projectID: string;
    };
  };
};

export const initialState: MapsStateType = {
  params: {},
  data: {
    loading: false,
    workers: {
      loading: false,
      fetched: false,
      data: {},
    },
    siteId: {
      fetched: false,
      projectID: '',
    },
  },
};
