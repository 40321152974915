import { produce } from 'immer';
import { initialState, SideModalState } from 'store/state/sideModal';
import { SIDE_MODAL_TOGGLE } from 'store/actions/actionTypes';
import { SideModalAction } from 'store/actions/sideModal';

const sitesReducer = (
  state: SideModalState = initialState,
  action: SideModalAction,
): SideModalState =>
  produce(state, (draft) => {
    switch (action.type) {
      case SIDE_MODAL_TOGGLE:
        if (action.payload) {
          draft.isOpened = true;
          draft.children = action.payload.children;
          draft.className = action.payload.className;
        } else {
          draft.isOpened = false;
          draft.children = null;
          draft.className = '';
        }
        break;
      default:
        break;
    }
  });

export default sitesReducer;
