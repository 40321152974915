import { IWorkPermit } from 'interfaces/workPermit/IWorkPermit';

export type WorkPermitsStore = {
  selectedWorkPermit: IWorkPermit | null | undefined;
  loading: boolean;
  data: IWorkPermit[];
};

const initialState: WorkPermitsStore = {
  selectedWorkPermit: null,
  loading: false,
  data: [],
};

export default initialState;
