import React from 'react';
import { Row } from 'antd';
import Loader from '../Loader';

function LoadingPage({ isHeight, fontSize = 48 }: any): React.ReactElement {
  return (
    <Row align="middle" justify="center" className={`${isHeight ? 'height-100' : 'full-page'}`}>
      <Loader isLoading fontSize={fontSize} />
    </Row>
  );
}

export default LoadingPage;
