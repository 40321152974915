import { produce } from 'immer';
import initialState, { MaintanceMapStore } from 'store/state/maintanceMap';
import {
  // MaintanceMapActions,
  MAINTANCE_MAP_TOGGLE_FULL_SCREEN,
  MAINTANCE_MAP_TOGGLE_PRESENTATION_VIEW,
  MAINTANCE_MAP_TOGGLE_ANCHOR_GRAPH_VIEW,
  MAINTANCE_MAP_MAP_RESET,
  MAINTANCE_MAP_IMAGE_LOADING,
  MAINTANCE_MAP_TOGGLE_PLAY_BACK_MODE,
  MAINTANCE_MAP_PLAY_BACK_TIME,
  MAINTANCE_MAP_SELECTED_FLOOR,
  MAINTANCE_MAP_WNT_INVENTORYS,
  MAINTANCE_MAP_INVENTORYS_SEARCH,
} from 'store/actions/actionTypes';

const maintanceMapReducer = (state = initialState, action: any): MaintanceMapStore =>
  produce(state, (draft) => {
    switch (action.type) {
      case MAINTANCE_MAP_TOGGLE_PRESENTATION_VIEW:
        draft.isPresentation = !draft.isPresentation;
        break;
      case MAINTANCE_MAP_TOGGLE_FULL_SCREEN:
        draft.isExpanded = !draft.isExpanded;
        break;
      case MAINTANCE_MAP_TOGGLE_ANCHOR_GRAPH_VIEW:
        draft.isAnchorGraphView = !draft.isAnchorGraphView;
        if (draft.isPlayBackMode) {
          draft.isPlayBackMode = false;
          draft.playBackTime = null;
        }
        break;
      case MAINTANCE_MAP_MAP_RESET:
        draft.isPresentation = false;
        draft.isExpanded = false;
        break;
      case MAINTANCE_MAP_IMAGE_LOADING:
        draft.isImageLoading = action.payload;
        break;
      case MAINTANCE_MAP_TOGGLE_PLAY_BACK_MODE:
        if (draft.isAnchorGraphView) {
          draft.isAnchorGraphView = false;
        }
        draft.isPlayBackClosed = draft.isPlayBackMode;
        draft.isPlayBackMode = !draft.isPlayBackMode;
        break;
      case MAINTANCE_MAP_PLAY_BACK_TIME:
        draft.playBackTime = action.payload;
        break;
      case MAINTANCE_MAP_SELECTED_FLOOR:
        draft.selectedFloor = action.payload;
        break;
      case MAINTANCE_MAP_WNT_INVENTORYS:
        draft.wntInventorys = action.payload;
        break;
      case MAINTANCE_MAP_INVENTORYS_SEARCH:
        draft.searchInventories = action.payload;
        break;
      default:
        break;
    }
  });

export default maintanceMapReducer;
