import { Coordinate } from 'store/state/floors';

export interface Anchor {
  id: string;
  wnt_inventory_id: string;
  approved: boolean;
  floor_id: string;
  network_id: string;
  coordinates?: { coordinates: Coordinate };
}

export interface PlanningAnchor {
  id: string;
  wnt_inventory_id: string;
  floor_id: string;
  network_id: string;
  coordinates: { coordinates: Coordinate };
}

export type PlannedAnchor = [number, number, string, number, string, string, number, string];

export interface Inventory {
  type: 'anchor' | 'gateway';
  status: 'on' | 'off' | 'err' | 'fall' | 'low' | 'N/A';
  id: number;
  wnt_network_id: number;
  wnt_inventory_id: number;
  group_of_site_id: number;
  site_id: number;
  blueprint_id: number;
  battery: number | string;
  approved: boolean;
  actual_coordinates: { coordinates: Coordinate };
  calculated_coordinates: { coordinates: Coordinate };
  floor_id: number;
  link_quality: { quality: number; link_quality_to: number };
  received_at: Date;
  error_rate: number;
  online_status?: boolean;
  anchor_fall?: boolean;
  isCalculatedPosition: boolean;
  neighbors?: Neighbor[];
  last_updated_at?: string;
}

export interface Neighbor {
  wnt_inventory_id: number;
  rssi: number;
}

export interface Anchors {
  data: {
    planning: PlanningAnchor[];
    approved: Anchor[];
    unapproved: Anchor[];
  };
  selected: string | null;
  isLoading: boolean;
  searchType: string;
  filterResUnapproved: any[];
  filterResApproved: any[];
  inventory: {
    approved: Inventory[];
    isLoading: boolean;
  };
}

const initialState = {
  data: {
    planning: [],
    approved: [],
    unapproved: [],
  },
  isLoading: false,
  selected: null,
  searchType: '',
  filterResUnapproved: [],
  filterResApproved: [],
  inventory: {
    approved: [],
    isLoading: false,
  },
};

export default initialState;
