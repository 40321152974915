import { State } from 'store/state';
import { User } from 'store/state/user';
import { createSelector } from 'reselect';

export const selectUser = (state: State): User => state.user;
export const checkRole = (role: string[]) => (state: State): boolean =>
  role.includes(state.user.role as string);
export const isRtlLanguage = (state: State): boolean => state.user.locale === 'ar';
export const selectPermissions = (state: State): string[] => state.user.permissions;
export const checkUserHasPermission = createSelector(
  selectPermissions,
  (__: any, permission: string) => permission,
  (permissions: string[], permission: string) => permissions.includes(permission),
);
export const selectIsSuperAdmin = (state: State): boolean => state.user.isSuperAdmin();
