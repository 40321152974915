import { Dispatch } from 'react';
import { ActionCreator, Action } from 'store/types';
import {
  SET_IS_SAFETY_EVACUATION,
  SET_SAFE_ZONE,
  SET_SAFETY_EVENTS,
  SET_SELECTED_EVENT,
  SET_SELECTED_SAFETY_INCIDENT,
  RESET_SAFETY_STORE,
  SET_GATEWAY_ID,
  SET_MAP_WORKERS,
  SET_MAP_WORKERS_LOADING,
  SET_MAP_ALL_WORKERS,
} from 'store/actions/actionTypes';
import { Zone } from 'store/state/zones';
import { WorkerDetailTypeOnMap } from 'store/state/safety';
import { getWorkers } from 'api/safety';
import moment from 'moment';
import { getStatusFromInventory } from 'ui/components/workers/utils';
import { STATUS } from 'ui/components/Safety/MapView/utils';
import { view } from '../floors';

export type SetEvacuation = Action<boolean>;

export function setIsEvacuation(isStarted: boolean): ActionCreator {
  return async (dispatch: Dispatch<SetEvacuation>): Promise<void> => {
    dispatch({ type: SET_IS_SAFETY_EVACUATION, payload: isStarted });
  };
}

export type SetSafeZone = Action<Zone>;

export function setSafeZone(zone: Zone): ActionCreator {
  return async (dispatch: Dispatch<SetSafeZone>): Promise<void> => {
    dispatch({ type: SET_SAFE_ZONE, payload: zone });
  };
}

export type SetEventList = Action<any>;

export function setEventList(data: any): ActionCreator {
  return async (dispatch: Dispatch<SetEventList>): Promise<void> => {
    dispatch({ type: SET_SAFETY_EVENTS, payload: data });
  };
}

export type SetSelectedEvent = Action<any>;

export function setSelectedEvent(data: any): ActionCreator {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    if (data && data.floor_id) {
      await dispatch(view('', data.floor_id));
    }
    dispatch({ type: SET_SELECTED_EVENT, payload: data });
  };
}

export function setSelectedSafetyIncident(data: any, currentWorkerFloorId: any): ActionCreator {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    if (currentWorkerFloorId) {
      await dispatch(view('', currentWorkerFloorId));
    }
    dispatch({ type: SET_SELECTED_SAFETY_INCIDENT, payload: data });
  };
}

export type SetGatewayId = Action<any>;

export function setGatewayId(id: string): ActionCreator {
  return async (dispatch: Dispatch<SetGatewayId>): Promise<void> => {
    dispatch({ type: SET_GATEWAY_ID, payload: id });
  };
}

export type SetMapWorkers = Action<WorkerDetailTypeOnMap[]>;

export function setMapWorkers(data: WorkerDetailTypeOnMap[]): ActionCreator {
  return async (dispatch: Dispatch<SetMapWorkers>): Promise<void> => {
    dispatch({ type: SET_MAP_WORKERS, payload: data });
  };
}

export function setWorkersLoading(isLoading: boolean): ActionCreator {
  return async (dispatch: Dispatch<Action<boolean>>): Promise<void> => {
    dispatch({ type: SET_MAP_WORKERS_LOADING, payload: isLoading });
  };
}

export type ResetStore = Omit<Action<any>, 'payload'>;

export function reset(): ActionCreator {
  return async (dispatch: Dispatch<ResetStore>): Promise<void> => {
    dispatch({ type: RESET_SAFETY_STORE });
  };
}

export const normalizeWorkers = (workers: WorkerDetailTypeOnMap[]): any => {
  return workers.map(
    ({
      id,
      coordinates,
      name,
      worker_tag_id,
      contact_number,
      role_name,
      subcontractor_name,
      last_seen,
      is_online,
      is_active,
      profile_picture_path,
      zone_name,
      blueprint_id,
      blueprint_name,
    }) => ({
      id,
      coordinates,
      name,
      worker_tag_id,
      contact_number: contact_number || '-NA-',
      worker_role_name: role_name,
      subcontractor_name: subcontractor_name || '-NA-',
      last_seen: last_seen || '-NA-',
      last_seen_at: last_seen ? moment(last_seen) : null,
      image: {
        is_online: getStatusFromInventory({ is_online, is_active }),
        url: profile_picture_path || null,
      },
      floor_id: blueprint_id,
      floor_name: blueprint_name,
      zone_name,
      status: is_online && is_active ? STATUS.active : STATUS.inactive,
      is_active,
    }),
  );
};

export function listMapWorkers(networkId: number, blueprintId?: number): ActionCreator {
  return async (dispatch: Dispatch<Action<any[]>>): Promise<void> => {
    try {
      const { data } = await getWorkers(networkId, blueprintId);
      dispatch({ type: SET_MAP_ALL_WORKERS, payload: normalizeWorkers(data) });
    } catch (err) {
      // @ts-ignore
      console.log('List Map Workers failed', err);
    }
  };
}

export type SafetyActions = SetEvacuation &
  SetSafeZone &
  SetEventList &
  SetSelectedEvent &
  SetMapWorkers &
  ResetStore;
