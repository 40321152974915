import { produce } from 'immer';
import { DAILY_ATTENDANCE_REPORT_PROJECT_ID } from 'store/actions/actionTypes';
import { initialState } from 'store/state/dailyAttendanceReport';

const dailyAttendanceReportReducer = (state = initialState, action: any): any =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case DAILY_ATTENDANCE_REPORT_PROJECT_ID:
        draft.data.siteId.projectID = action.payload;
        draft.data.siteId.fetched = true;
        break;
      default:
        break;
    }
  });

export default dailyAttendanceReportReducer;
