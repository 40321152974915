import { produce } from 'immer';
import {
  MAPS_UPDATE_SEARCH_PARAMS,
  MAPS_ALL_WORKERS_LOADING,
  MAPS_ADD_ALL_WORKERS,
  MAPS_PROJECT_ID,
  MAPS_WORKERS_DATA_LOADING,
  MAPS_STATE_RESTORE,
} from 'store/actions/actionTypes';
import { initialState } from 'store/state/maps';

const mapsReducer = (state = initialState, action: any): any =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case MAPS_UPDATE_SEARCH_PARAMS:
        draft.params = { ...draft.params, ...action.payload };
        break;
      case MAPS_ALL_WORKERS_LOADING: {
        if (action.payload) {
          draft.data.workers.data = [];
          draft.data.workers.fetched = false;
        }
        draft.data.workers.loading = action.payload;
        break;
      }
      case MAPS_ADD_ALL_WORKERS:
        draft.data.workers.data = action.payload;
        draft.data.workers.fetched = true;
        break;
      case MAPS_PROJECT_ID:
        draft.data.siteId.projectID = action.payload;
        draft.data.siteId.fetched = true;
        break;
      case MAPS_WORKERS_DATA_LOADING:
        draft.data.loading = !draft.data.loading;
        break;
      case MAPS_STATE_RESTORE:
        draft.data.loading = false;
        draft.data.workers.loading = false;
        draft.data.workers.fetched = false;
        draft.data.workers.data = {};
        draft.data.siteId.fetched = false;
        draft.data.siteId.projectID = '';
        break;
      default:
        break;
    }
  });

export default mapsReducer;
