import { Zone } from '../zones';

export type WorkerDetailTypeOnMap = {
  id: number;
  name: string;
  worker_tag_id: string;
  contact_number: string;
  media_url: string;
  profile_picture_path: string;
  worker_role_name: string;
  role_name: string;
  subcontractor_name: string;
  is_active: boolean;
  is_online: boolean;
  last_seen: string;
  coordinates: any;
  floor_id: string;
  status?: string;
  floor_name: string;
  zone_id: string;
  zone_name: string;
  blueprint_id: number;
  blueprint_name: string;
  isIncludedEvacuation: boolean | undefined;
};

export type SafetyState = {
  isEvacuation: boolean;
  safetyEvents: any[];
  safeZone: Zone | undefined;
  selectedEvent: any;
  gateWayId: string | undefined;
  isBluprintLoaded: boolean;
  workers: WorkerDetailTypeOnMap[];
  allWorkers: WorkerDetailTypeOnMap[];
  isWorkersLoading: boolean;
  floorIds: number[];
  zoneIds: number[];
  assemblyZoneIds: number[];
  evacuationFloorsIds: number[];
  updatedAt: string;
  evacuationStartAt: string;
  isWorkersFetched: boolean;
  isEvacuationLoading: boolean;
  evacuationSessionId: string | undefined;
};

export const initialState = {
  isEvacuation: false,
  safetyEvents: [],
  safeZone: undefined,
  selectedEvent: undefined,
  gateWayId: undefined,
  isBluprintLoaded: false,
  workers: [],
  allWorkers: [],
  isWorkersLoading: false,
  floorIds: [],
  zoneIds: [],
  assemblyZoneIds: [],
  evacuationFloorsIds: [],
  updatedAt: '',
  evacuationStartAt: '',
  isWorkersFetched: false,
  isEvacuationLoading: false,
  evacuationSessionId: undefined,
};
