import { User } from 'store/state/user';

export const SUPER_ADMIN = 'super-admin';
export const CLIENT_ADMIN = 'client-admin';
export const SITE_ADMIN = 'group-of-sites-admin';
export const SITE_VIEW = 'group-of-sites-viewer';
export const CREW_MANAGER = 'crew-manager';
export const SITE_ENGINEER = 'site-engineer';
export const SAFETY_DEMO_VIEW = 'safety-demo';

export const userRoles = {
  'client-admin': 'Client Admin',
  'group-of-sites-admin': 'Site Admin',
  'group-of-sites-viewer': 'Site Viewer',
  'crew-manager': 'Crew Manager',
  'site-engineer': 'Site Engineer',
  'safety-demo': 'Safety Demo',
};

// @ts-ignore
export const hasRoles = (roles: string[], user: User): boolean => roles.includes(user.role);
