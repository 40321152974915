import produce from 'immer';
import { WorkShiftStore, initialState } from 'store/state/workshifts';
import {
  TOOGLE_WORKSHIFT_FETCH,
  IN__LIST_WORKSHIFTS_REQ,
  IN__LIST_WORKSHIFTS_SUCCESS,
  IN__LIST_WORKSHIFTS_FAILED,
} from 'store/actions/actionTypes';

export default (state: WorkShiftStore = initialState, action: any): WorkShiftStore =>
  produce(state, (draft) => {
    switch (action.type) {
      case TOOGLE_WORKSHIFT_FETCH:
        draft.fetchWorkshifts = !draft.fetchWorkshifts;
        break;
      case IN__LIST_WORKSHIFTS_REQ:
        draft.isLoading = true;
        draft.data = [];
        break;
      case IN__LIST_WORKSHIFTS_FAILED:
        draft.isLoading = false;
        break;
      case IN__LIST_WORKSHIFTS_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      default:
        break;
    }
  });
