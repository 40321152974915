const initialState = {
  assetsStatesCounts: {
    totalUnassignedAssets: 0,
    totalActiveAssets: 0,
    totalInActiveAssets: 0,
    totalOfflineAssets: 0,
    totalAssets: 0,
  },
};

export default initialState;
