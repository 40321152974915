type WokersListLableType = {
  activity: string;
  siteEngineer: string;
  foreMan: string;
  workers: string;
  searchWorkers: string;
  nA: string;
};

type WorkersListColumnsType = {
  dataIndex: string;
  render?: any;
  width: number;
};

type LocationGroupType = {
  id: number;
  name: string;
};

export type DelayCodesType = {
  id: number;
  delay_code: number;
};

export interface ProductivityColumnType {
  header: string;
  field: string;
  sort?: { enable: boolean; defaultOrder: string };
  filter?: boolean | string;
  enableValue?: boolean;
  cellRenderer?: string;
}

export type DelayCodeType = {
  id: number;
  activity_id: number;
  delay_code: number;
  delay_description: string;
  hours_lost: number;
  notes: string;
};

export interface ProductivityListType {
  id: number;
  activity_date: string;
  site_engineer_name?: string;
  foreman_name?: string;
  location_group?: string;
  section_description: string;
  activity_code: number;
  activity_description: string;
  measuring_unit: string;
  target_quantity: number | string;
  planned_quantity?: number | string;
  target_rate: number;
  target_hours?: number | string;
  planned_hours?: number | string;
  total_booked_duration?: number | string;
  total_booked_hours_by_cm?: number | string;
  total_modified_duration?: number | string;
  actual_quantity?: number | string;
  actual_quantity_by_cm?: number | string;
  modified_actual_quantity?: number | string;
  actual_productivity_rate?: number | string;
  gain_loss_hours?: number | string;
  target_quantity_by_cm?: number | string;
  delay_codes?: DelayCodeType[] | string;
  delay_code_count: number | string;
  delay_codes_ids: DelayCodesType[] | string;
  delay_lost_hours: string[] | string;
  location_group_tag_id: LocationGroupType;
  Crews?: any;
  status: string;
  LocationGroup?: any;
  SiteEngineer?: any;
  Foreman?: any;
  DelayCodes?: any;
  Sic?: any;
  location_group_id?: number;
  key?: string;
  crewNames?: string[] | string;
  edited_by_cm?: boolean;
}

export interface WorkersListProductivityType {
  id: number;
  helmet_color: string;
  name: string;
  site_id: number;
  contact_number: string;
  created_at: Date;
  media_url: string;
  inventory_id: number;
  subcontractor_id: number;
  worker_role_id: number;
  worker_tag_id: number;
  WorkerRole: any;
  Subcontractor: any;
  Inventory: any;
  AssignedCrews: any;
  AssignedActivity: any;
}

export interface EngineerDataType {
  data: number | string | null;
  label: string;
  className: string;
}

export interface WorkerListType {
  columns: Array<WorkersListColumnsType>;
  loading: boolean;
  productivityWorkersList: Array<WorkersListProductivityType>;
  LABEL: WokersListLableType;
  paging?: any;
  handleTableChange?: any;
}
export type ProductivityStateType = {
  isToggleFetch: boolean;
};

const initialState: ProductivityStateType = {
  isToggleFetch: false,
};

export default initialState;
