import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { APP } from 'utils/routes';
import { selectUser as selectLoggedUser } from 'store/selectors/user';

function PublicRoute(props: RouteProps): React.ReactElement {
  const userInfo = useSelector(selectLoggedUser);
  if (userInfo && userInfo.isAuthenticated) {
    return <Redirect to={{ pathname: APP }} />;
  }

  return <Route {...props} />;
}

export default React.memo(PublicRoute);
