import { produce } from 'immer';
import initialState, { Zones /* , { Zones } */ } from 'store/state/zones';
import {
  LIST_ZONES_REQ,
  LIST_ZONES_SUCCESS,
  LIST_ZONES_FAILED,
  VIEW_ZONE,
  RESET_ZONE_SEARCH,
  SEARCH_ZONE,
  RESET_ZONES,
  // ZonesActions,
} from 'store/actions/actionTypes';

export default (state: Zones = initialState, action: any): Zones =>
  produce(state, (draft) => {
    // let filterSearchValue;
    switch (action.type) {
      case LIST_ZONES_REQ:
        // case EDIT_ZONE_REQ:
        draft.isLoading = true;
        break;
      case LIST_ZONES_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        draft.searchType = '';
        draft.filterResult = [];
        break;
      // case EDIT_ZONE_SUCCESS:
      case LIST_ZONES_FAILED:
        draft.isLoading = false;
        break;
      case VIEW_ZONE:
        draft.selected = action.payload;
        break;
      case SEARCH_ZONE:
        const filterSearch = draft.data.filter(
          (item: any) => item.name.toLowerCase().includes(action.payload.toLowerCase()),
          /* eslint-disable-next-line */
        );
        draft.searchType = action.payload;
        draft.filterResult = filterSearch;
        break;
      case RESET_ZONE_SEARCH:
        draft.searchType = '';
        draft.filterResult = draft.data;
        break;
      case RESET_ZONES:
        draft.data = initialState.data;
        draft.filterResult = initialState.filterResult;
        draft.isLoading = false;
        draft.searchType = initialState.searchType;
        draft.selected = initialState.selected;
        break;
      default:
        break;
    }
  });
